import React, { FC } from "react";

interface Props {
  selected: boolean;
  activeColor: string;
  inactiveColor: string;
  size: number | string;
}

const Star: FC<Props> = ({ selected, activeColor, inactiveColor, size }) => {
  return (
    <svg
      style={{
        color: selected ? activeColor : inactiveColor,
        fill: selected ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0.04)",
        display: "block",
        height: size,
        width: size,
        transition: "color 0.5s ease-in-out, fill 0.5s ease-in-out",
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"
      />
    </svg>
  );
};

export default Star;
