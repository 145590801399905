import { useEffect, useState } from "react";
import { getDeliveryAreas } from "../services/ShippingService";

export const useDeliveryAreas = () => {
  const [deliveryAreas, setDeliveryAreas] = useState<IServiceArea[]>([]);
  const [deliveryAreasLoading, setDeliveryAreasLoading] = useState<boolean>(true);

  const fetchDeliveryAreas = () => {
    setDeliveryAreasLoading(true);
    getDeliveryAreas()
      .then((res: any) => {
        setDeliveryAreas(res.data);
      })
      .finally(() => {
        setDeliveryAreasLoading(false);
      });
  };

  useEffect(() => {
    fetchDeliveryAreas();
  }, []);

  return {
    deliveryAreas,
    deliveryAreasLoading,
    fetchDeliveryAreas,
  };
};
