import { useEffect, useState } from "react";
import { getOrdersSummed } from "../services/ShippingService";
import moment from "moment";

export const useOrderHistorySummed = (date: Date) => {
  // const user = useAppSelector(state => state.auth.user);

  const [orderHistory, setOrderHistory] = useState<IOrderData[]>([]);
  const [orderHistoryLoading, setOrderHistoryLoading] = useState<boolean>(false);

  const fetchOrderHistory = () => {
    /*if (!user) {
            setOrderHistory([]);
            return;
        }*/
    const momentDate = moment(date);

    setOrderHistoryLoading(true);
    getOrdersSummed(momentDate.year(), momentDate.week())
      .then((res: any) => {
        setOrderHistory(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setOrderHistoryLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderHistory();
  }, [date]);

  return {
    orderHistory,
    orderHistoryLoading,
    fetchOrderHistory,
  };
};
