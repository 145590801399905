import React, { useEffect, useState } from "react";
import {
    IonRow,
    IonCol,
    IonIcon,
    IonModal,
    IonContent,
    IonGrid,
    IonFab,
    IonFabButton,
} from "@ionic/react";
import {
    arrowForwardOutline,
    calendar,
    time,
    close,
    heart,
    location,
    logOut,
    menu,
    notifications,
    settings,
    star,
    people
} from "ionicons/icons";
import { setProfileModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { useDispatch, useSelector } from "react-redux";

import { ProfileTabs } from "../../../models/Profile";
import { useAuth } from "../../../context/AuthContext";
import FavouritesTab from "./tabs/FavouritesTab";
import SettingsTab from "./tabs/SettingsTab";
import OrdersTab from "./tabs/OrdersTab";
import AddressListTab from "./tabs/AddressListTab";
import RemindersTab from "./tabs/RemindersTab";
import RatingsTab from "./tabs/RatingsTab";

import "./ProfileModal.scss";
import PersonsTab from "./tabs/PersonsTab";
import OrdersCancelTab from "./tabs/OrdersCancelTab";

const tabs = [
    {
        name: "Adataim",
        icon: settings,
        tab: "data",
    },
    {
        name: "Szállítási címek",
        icon: location,
        tab: "addresses",
    },
    {
        name: "Emlékeztető",
        icon: notifications,
        tab: "notifications",
    },
    {
        name: "Rendeléseim",
        icon: calendar,
        tab: "orders",
    },
    {
        name: "Lemondás",
        icon: time,
        tab: "order-cancels",
    },
    {
        name: "Kedvenceim",
        icon: heart,
        tab: "favourites",
    },
    {
        name: "Értékeléseim",
        icon: star,
        tab: "ratings",
    },
    {
        name: "Személyek",
        icon: people,
        tab: "persons",
    },
];

const ProfileModal: React.FC = () => {
    const { setLogout } = useAuth();
    const dispatch = useDispatch();

    const [modalStatus, setModalStatus] = useState<{
        show: boolean;
        activeTab: ProfileTabs;
    }>({ show: false, activeTab: "data" });

    const profileModalFromReducer = useSelector(
        (state: RootState) => state.ProfileModalReducer
    );

    useEffect(() => {
        setShowTabs(true);
        setModalStatus({
            show: profileModalFromReducer.showModal,
            activeTab: profileModalFromReducer.activeTab,
        });
    }, [profileModalFromReducer]);

    const onCloseModal = () => {
        dispatch(setProfileModal(false, modalStatus.activeTab));
    };

    const onModalTabButtonClick = (activeTab: ProfileTabs) => {
        setShowTabs(false);
        setModalStatus({
            show: modalStatus.show,
            activeTab: activeTab,
        });
    };

    const getActiveTab = () => {
        switch (modalStatus.activeTab) {
            case "addresses":
                return <AddressListTab />;
            case "notifications":
                return <RemindersTab />;
            case "favourites":
                return <FavouritesTab />;
            case "orders":
                return <OrdersTab />;
            case "order-cancels":
                return <OrdersCancelTab />;
            case "ratings":
                return <RatingsTab />;
            case "persons":
                return <PersonsTab />;
            case "data":
            default:
                return <SettingsTab />;
        }
    };

    const [showTabs, setShowTabs] = useState<boolean>(true);

    return (
        <IonModal
            isOpen={modalStatus.show}
            onDidDismiss={onCloseModal}
            cssClass="modal-fullscreen modal-large profile-modal"
        >
            <button onClick={onCloseModal} className="modal-close">
                <IonIcon icon={close} />
            </button>
            <IonContent>
                <IonGrid>
                    <IonRow className="h-100">
                        <IonCol
                            size="4"
                            className={
                                "profile-tabs h-100" +
                                (showTabs ? " showTabs" : "")
                            }
                        >
                            <div className="profile-tabs-wrapper">
                                {tabs.map((tab: any, _: number) => (
                                    <button
                                        key={_}
                                        id={"tabButton" + tab.tab}
                                        className={
                                            modalStatus.activeTab === tab.tab
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() =>
                                            onModalTabButtonClick(tab.tab)
                                        }
                                    >
                                        <div className="iconHolder">
                                            <IonIcon icon={tab.icon} />
                                        </div>
                                        {tab.name ?? ""}
                                        <IonIcon
                                            icon={arrowForwardOutline}
                                            className="arrow"
                                        />
                                    </button>
                                ))}
                                <button
                                    id="tabButtonLogout"
                                    onClick={() => {
                                        setLogout();
                                        setModalStatus({
                                            show: false,
                                            activeTab: "data",
                                        });
                                    }}
                                >
                                    <div className="iconHolder">
                                        <IonIcon icon={logOut} />
                                    </div>
                                    Kijelentkezés
                                </button>
                            </div>
                        </IonCol>
                        <IonCol
                            size="8"
                            className="profile-tab-content"
                            id="scrollableMyProfileCol"
                        >
                            {getActiveTab()}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            {!showTabs && (
                <IonFab
                    vertical="bottom"
                    horizontal="start"
                    id="mobileMenuFab"
                    slot="fixed"
                    onClick={() => setShowTabs((prevState) => !prevState)}
                >
                    <IonFabButton>
                        <IonIcon icon={menu} />
                    </IonFabButton>
                </IonFab>
            )}
        </IonModal>
    );
};

export default ProfileModal;
