import React, { useState } from "react";
import { useDeliveryAreas } from "../hooks/useDeliveryAreas";
import SuspenseLoader from "../components/utils/SuspenseLoader";
import PageWrapper from "../layout/PageWrapper";
import { IonCard, IonCardContent, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow } from "@ionic/react";
import Empty from "../components/utils/Empty";

import "./PageDeliveryAreas.scss";
import Header from "../layout/Header";
import MainTopBanner from "../components/MainTopBanner";

const PageDeliveryAreas: React.FC = () => {
  const [searchVal, setSearchVal] = useState("");
  const { deliveryAreas, deliveryAreasLoading, fetchDeliveryAreas } = useDeliveryAreas();
  const grouped = deliveryAreas
    .filter((deliveryArea) => {
      if (deliveryArea.city_name.toLowerCase().includes(searchVal.toLowerCase())) {
        return true;
      }
      if (deliveryArea.postal_code.toLowerCase().includes(searchVal.toLowerCase())) {
        return true;
      }
      return false;
    })
    .filter((val, index, array) => {
      return array.findIndex((a) => a.city_name === val.city_name) === index;
    })
    .sort((a, b) => a.city_name.localeCompare(b.city_name))
    .reduce((store: any, a) => {
      const letter = a.city_name[0];
      const keyStore = store[letter] || (store[letter] = []);
      keyStore.push(a);
      return store;
    }, {});

  return (
    <div>
      <Header />
      <PageWrapper smallContainer={true}>
        <IonGrid className="marginInner">
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardContent style={{ textAlign: "left" }}>
                  <p style={{ marginBottom: 30 }}>Ebédrendelés és ebéd házhoz szállítás az alábbi területeken lehetséges:</p>
                  <SuspenseLoader loading={deliveryAreasLoading}>
                    <IonItem lines="none" className={"rounded-input small-input "}>
                      <IonLabel position="floating">Keresés irányítószám vagy név alapján</IonLabel>
                      <IonInput type="text" title="Keresés irányítószám vagy név alapján..." placeholder="Keresés irányítószám vagy név alapján..." onIonChange={(e: any) => setSearchVal(e.target.value)} value={searchVal}></IonInput>
                    </IonItem>

                    {Object.keys(grouped).length > 0 ? (
                      <div className="delivery-areas">
                        {Object.keys(grouped).map((char) => (
                          <div className="group">
                            <div className="group-head">{char}</div>
                            <div className="group-items">
                              {grouped[char].map((deliveryArea: IServiceArea) => (
                                <span key={`delivery-area-${deliveryArea.id}`}>{deliveryArea.city_name ?? ""}</span>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Empty>Nem található szállítási terület</Empty>
                    )}
                  </SuspenseLoader>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </PageWrapper>
    </div>
  );
};
export default PageDeliveryAreas;
