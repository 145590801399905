import moment from "moment";
import { ModelBanner, ModelCartItem } from "../../models/FoodModel";
import { getDays } from "../../services/FoodService";
import { ModelOrderData } from "../../models/ShippingModel";
import { isFuture } from "date-fns";

export const fetchDates = async (year?: number | null, week?: number | null) => {
  let localYear = year;
  if (localYear === null || localYear === undefined) {
    localYear = moment().year();
  }

  let localWeek = week;
  if (localWeek === null || localWeek === undefined) {
    localWeek = moment().week();
  }

  return getDays(localYear, localWeek);
};

export const getBannerByCategory = (categoryId: number, banners: Array<ModelBanner>): ModelBanner | null => {
  const foundedBannerIndex = banners.findIndex((banner) => banner.category_id === categoryId);
  if (foundedBannerIndex !== -1) {
    return banners[foundedBannerIndex];
  }
  return null;
};

export const getImageLink = (menu_item_id: number, width: number, height: number) => {
  return process.env.REACT_APP_API_URL + "/v1/i?menu_item_id=" + menu_item_id + "&width=" + width + "&height=" + height;
};

export const canModifyOrderItem = (cart_item: ModelCartItem|ICartItem, order: ModelOrderData|IOrderData): boolean => {
  if (cart_item.quantity === 0) {
    return false;
  }
  let deadline = cart_item.menu_item.cancel_deadline ? new Date(cart_item.menu_item.cancel_deadline) : new Date();
  if (order.payment_status === "order_paid" && !cart_item.menu_item.disabled && isFuture(deadline)) {
    return true;
  }
  return false;
};

export const canModifyOrder = (order: ModelOrderData|IOrderData): boolean => {
  let countOfNotOrderableItems = 0;

  let items: ModelCartItem[]|ICartItem[] = [];
  if ((order as ModelOrderData).cart.items !== undefined) {
    items = (order as ModelOrderData).cart.items;
  } else {
    items = (order as IOrderData).cart.cart_items;
  }

  for (const cart_item of items) {
    if (!canModifyOrderItem(cart_item, order)) {
      countOfNotOrderableItems++;
    }
  }

  if (countOfNotOrderableItems !== items.length) {
    return true;
  }
  return false;
};
