import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { DEFAULT_CALORIE, DEFAULT_CARB, ModelBanner, ModelCalorie, ModelFilter } from "../models/FoodModel";
import { ModelCategory, ModelCategoryGroup } from "../models/CategoryModel";

import FoodDetailsMenu from "../components/FoodDetailsMenu";
import Category from "../components/Category";
import MessageCenter from "../components/MessageCenter";

import PageWrapper from "../layout/PageWrapper";
import Header from "../layout/Header";

import { getMenu, processCategoryGroups } from "../services/FoodService";

import { fetchDates, getBannerByCategory } from "../helpers/functions/OrderPageHelpers";
import { handleApiError, handleApiResponseError } from "../helpers/form/GlobalHelpers";
import moment from "moment";
import MainTopBanner from "../components/MainTopBanner";
import { IonSpinner } from "@ionic/react";

import lastMinuteBanner from "../assets/images/cityfood_last-minute_banner_1170x300px.jpg";

import "react-image-lightbox/style.css";

interface IProps {
  currentWeek: {
    weekData: { year: number | null; week: number | null };
    weekDataLoaded: boolean;
  };
}

const LastMinutePage: React.FC<IProps> = ({ currentWeek }) => {
  const dispatch = useDispatch();

  const [dates, setDates] = useState<{
    dates: Array<{ date: Date; shipping_mode: string }>;
    error: boolean;
  }>({ error: false, dates: [] });
  const [categoryGroups, setCategoryGroups] = useState<{
    error: boolean;
    categoryGroupsLoaded: boolean;
    categoryGroups: Array<ModelCategoryGroup>;
  }>({ error: false, categoryGroupsLoaded: false, categoryGroups: [] });
  const [banners, setBanners] = useState<{
    error: boolean;
    bannersLoaded: boolean;
    banners: Array<ModelBanner>;
  }>({
    error: false,
    bannersLoaded: false,
    banners: [],
  });
  const [topBanners, setTopBanners] = useState<{
    error: boolean;
    bannersLoaded: boolean;
    banners: Array<ModelBanner>;
  }>({
    error: false,
    bannersLoaded: true,
    banners: [],
  });

  const fetchCategories = () => {
    if (!currentWeek.weekDataLoaded) {
      return;
    }
    getMenu(currentWeek.weekData.year, currentWeek.weekData.week, {
      filtersLoaded: true,
      filters: {
        labels: [],
        ingredients: [],
        favouritesOnly: false,
        lastMinutesOnly: true,
        calorie: DEFAULT_CALORIE,
        carb: DEFAULT_CARB,
      },
    })
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt az ételek lekérdezése közben, kérjük próbálja újra később...")) {
          setCategoryGroups({
            error: true,
            categoryGroupsLoaded: true,
            categoryGroups: [],
          });
          return;
        }

        if (res.data.data.length === 0) {
          setCategoryGroups({
            error: true,
            categoryGroupsLoaded: true,
            categoryGroups: [],
          });
          return;
        }

        try {
          setCategoryGroups({
            error: false,
            categoryGroupsLoaded: true,
            categoryGroups: processCategoryGroups(res, dates.dates),
          });
        } catch (error) {
          console.error(error);
          // expected output: ReferenceError: nonExistentFunction is not defined
          // Note - error messages will vary depending on browser
        }
      })
      .catch((err: any) => {
        if (err.message === "REQ_CANCEL") {
          return;
        }

        setCategoryGroups({
          error: true,
          categoryGroupsLoaded: false,
          categoryGroups: [],
        });
        handleApiError(dispatch, err, "Hiba történt az ételek lekérdezése közben, kérjük próbálja újra később...");
      });
  };

  useEffect(() => {
    if (dates.dates.length !== 0) {
      fetchCategories();
    }

    return () => {
      setCategoryGroups({
        error: false,
        categoryGroupsLoaded: false,
        categoryGroups: [],
      });
    };
  }, [dates, currentWeek]);

  useEffect(() => {
    if (currentWeek.weekDataLoaded) {
      fetchDates(currentWeek.weekData.year, currentWeek.weekData.week)
        .then((res: any) => {
          if (handleApiResponseError(dispatch, res, "Hiba történt a dátumok lekérdezése közben, kérjük próbálja újra később...")) {
            setDates({ error: true, dates: [] });
            return;
          }
  
          if (res.data.data.length > 0) {
            let tempDates = new Array<{
              date: Date;
              shipping_mode: string;
            }>();
            Object.values(res.data.data).map((date: any) => {
              tempDates.push({
                date: date.date,
                shipping_mode: date.shipping_mode,
              });
            });
            setDates({ error: false, dates: tempDates });
          } else {
            setDates({ error: true, dates: [] });
            return;
          }
        })
        .catch((err: any) => {
          setDates({ error: true, dates: [] });
          handleApiError(dispatch, err, "Hiba történt a dátumok lekérdezése közben, kérjük próbálja újra később...");
        });
    }

    return () => {
      setDates({ error: false, dates: [] });
    };
  }, [currentWeek]);

  useEffect(() => {
    const header = document.getElementById("HeaderBottom");
    const banner = document.getElementById("TopBanner");
    const categoryMenu = document.getElementById("CategoryGroupSidebar");
    window.addEventListener("scroll", () => {
      if (categoryMenu !== null) {
        if (window.innerWidth < 600) {
          if (window.pageYOffset >= 120) {
            header.classList.add("sticky");
            banner.classList.add("sticky");
            categoryMenu.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
            banner.classList.remove("sticky");
            categoryMenu.classList.remove("sticky");
          }
        } else if (window.innerWidth < 1025) {
          if (window.pageYOffset >= 150) {
            header.classList.add("sticky");
            banner.classList.add("sticky");
            categoryMenu.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
            banner.classList.remove("sticky");
            categoryMenu.classList.remove("sticky");
          }
        } else {
          if (window.pageYOffset >= 300) {
            header.classList.add("sticky");
            banner.classList.add("sticky");
            categoryMenu.classList.add("sticky");
          } else {
            header.classList.remove("sticky");
            banner.classList.remove("sticky");
            categoryMenu.classList.remove("sticky");
          }
        }
      }
    });
  }, []);

  return (
    <div>
      <Header type="lastMinute" />
      <MainTopBanner topBanners={topBanners} />
      <PageWrapper className="LastMinutePage">
        <MessageCenter page="lastMinute" />

        <h1 className="ion-text-left">Last Minute</h1>

        {categoryGroups.categoryGroupsLoaded ? (
          <>
            {categoryGroups.categoryGroups.length > 0 ? (
              <>
                <p className="ion-text-left">
                  Holnapra a következő választékunk rendelhető a készlet erejéig (Fontos, hogy a Last minute során megrendelt ételek lemondására nincs lehetőség. Kérjük, fokozott figyelemmel adjanak le rendelést és válasszanak fizetési
                  módot!):
                </p>

                {categoryGroups.categoryGroups.map((categoryGroup: ModelCategoryGroup, _: number) => (
                  <div className="CategoryGroup" key={categoryGroup.id} id={"category-" + categoryGroup.id}>
                    {categoryGroup.categories.map((category: ModelCategory, _: number) => (
                      <Category key={"last_" + category.id} category={category} banner={getBannerByCategory(category.id, banners.banners)} version={null} lastMinute />
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <p className="ion-text-left">Jelenleg nincs last minute ajánlatunk...</p>
            )}
          </>
        ) : (
          <div className="ion-padding">
            <IonSpinner name="crescent" />
          </div>
        )}

        <br />
        <p className="ion-text-left">
          2022. október 1-től a rendelések leadása 2 nappal korábban 16:00 óráig lehetséges, de, ha kifutna az időből, néhány ételből van lehetőség a szállítást megelőző nap 9 óra után (csak a weboldalon keresztül), 16 óráig rendelni a
          készlet erejéig "last minute" szolgáltatásunk keretében, mely választék 9-11 közötti időben jelenik meg itt és a készlet függvényében módosul (tehát nem a megszokott étlap menüpont alatt).
        </p>
        <img src={lastMinuteBanner} alt="" />
        <FoodDetailsMenu />
      </PageWrapper>
    </div>
  );
};
export default LastMinutePage;
