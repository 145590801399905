import React, { useEffect, useState } from "react";
import { IonButton, IonItem, IonLabel, IonInput } from "@ionic/react";

import useFormValidation from "../../../helpers/form/FormValidationHelper";
import ValidateLostPassword from "../../../helpers/validators/ValidateLostPassword";
import { addApiErrors, hasError, setInitError, ErrorsList, handleApiError, handleApiResponseError } from "../../../helpers/form/GlobalHelpers";
import { addToast } from "../../toast/Actions";
import { useDispatch, useSelector } from "react-redux";
import { sendLostPasswordEmail } from "../../../services/UserService";

import { setLostPasswordModal } from "./Actions";
import { RootState } from "../../RootReducer";
import { addLoading, removeLoading } from "../../loading/Actions";
import ModalWithImage from "../../../components/modals/ModalWithImage";

const INIT_STATE = {
  email: "",
};

const LostPasswordModal: React.FC = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const lostPasswordModalFromReducer = useSelector((state: RootState) => state.LostPasswordModalReducer.showModal);
  useEffect(() => {
    setShow(lostPasswordModalFromReducer);

    return () => {
      setShow(false);
    };
  }, [lostPasswordModalFromReducer]);

  const { handleChange, values } = useFormValidation(INIT_STATE);

  const [status, setStatus] = useState({
    error_list: INIT_STATE,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      handleSendEmailAddress();
    }
  };

  const handleSendEmailAddress = async () => {
    dispatch(addLoading());
    setInitError(INIT_STATE, setStatus);

    const errors = ValidateLostPassword(values);
    if (hasError(errors)) {
      dispatch(removeLoading());
      addApiErrors(errors, setStatus);
    } else {
      sendLostPasswordEmail(values.email, window.location.origin)
        .then((res: any) => {
          dispatch(removeLoading());
          if (handleApiResponseError(dispatch, res, "Hiba történt az email cím elküldése közben...", setStatus)) {
            return;
          }
          dispatch(
            addToast({
              message: "Email kiküldve a megadott email címre",
              duration: 3000,
              color: "success",
            })
          );
          dispatch(setLostPasswordModal(false));
          values.email = "";
        })
        .catch((err: any) => {
          handleApiError(dispatch, err, "Hiba történt az email cím elküldése közben...", setStatus);
        });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const onCloseModal = () => {
    dispatch(setLostPasswordModal(false));
  };

  return (
    <ModalWithImage
      title="Elfelejtett jelszó"
      subtitle="Tisztelt vásárlónk, amennyiben elfelejtette a belépéshez szükséges jelszavát, adja meg a
            belépéshez használt e-mail címét, melyre egy linket fogunk küldeni, ahol megadhatja új
            jelszavát."
      onCloseModal={onCloseModal}
      show={show}
      id="lostPassModal"
    >
      <div className="form">
        <form className="form" onSubmit={handleSubmit}>
          <IonItem lines="none" className={"rounded-input small-input " + (Boolean(status.error_list) && status.error_list.email ? "error" : "")}>
            <IonLabel position="floating">E-mail cím</IonLabel>
            <IonInput
              title="E-mail cím"
              onIonInput={handleChange}
              onKeyDown={handleKeyDown}
              clearOnEdit={false}
              autocomplete="email"
              type="email"
              inputmode="email"
              pattern="email"
              name="email"
              value={values.email}
              required
              autofocus={true}
              data-testid="input-lost-password-email"
            ></IonInput>
          </IonItem>

          <ErrorsList errors={status.error_list} />

          <div className="ion-text-center">
            <IonButton id="sendEmailButton" size="large" expand="block" onClick={handleSendEmailAddress}>
              Küldés
            </IonButton>
          </div>
        </form>
      </div>
    </ModalWithImage>
  );
};

export default LostPasswordModal;
