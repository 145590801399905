import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonCol,
    IonRow,
    IonIcon,
} from "@ionic/react";
import { useAuth } from "../context/AuthContext";
import { useDispatch } from "react-redux";
import Header from "../layout/Header";
import { setLoginModal } from "../store/modals/login/Actions";
import { setRegistrationModal } from "../store/modals/registration/Actions";
import PageWrapper from "../layout/PageWrapper";
import { useOrderHistorySummed } from "../hooks/useOrderHistorySummed";
import OrderHistory from "../components/order-summed/OrderHistory";
import { arrowBack, arrowForward } from "ionicons/icons";

import "./SummedOrdersPage.scss";
import moment from "moment";

const SummedOrdersPage: React.FC = () => {
    const dispatch = useDispatch();
    const { authInfo } = useAuth();
    const [loggedIn, setLoggedIn] = useState<boolean>(authInfo.loggedIn);
    const [date, setDate] = useState(new Date);
    const { orderHistory, orderHistoryLoading, fetchOrderHistory } = useOrderHistorySummed(date);

    useEffect(() => {
        if (authInfo.loggedIn !== loggedIn) {
            setLoggedIn(authInfo.loggedIn);
        }
    }, [authInfo]);

    useEffect(() => {
        if (loggedIn) {
            fetchOrderHistory();
        }
    }, [loggedIn]);

    const onOpenLoginModal = () => {
        dispatch(setLoginModal(true));
    };

    const onOpenRegModal = () => {
        dispatch(setRegistrationModal(true));
    };

    if (authInfo.loggedIn === null || authInfo.loggedIn === false) {
        onOpenLoginModal();
    }

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                {authInfo.loggedIn ? (
                    <IonGrid className="marginInner">
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardContent>
                                        <div className="date-grid">
                                            <IonButton onClick={() => setDate((d) => {
                                                return new Date(d.setDate(d.getDate() - 7));
                                            })}>
                                                <IonIcon icon={arrowBack} />
                                            </IonButton>
                                            <div className="date-grid-center">
                                                <b>{moment(date).year()} - {moment(date).week()}. hét</b>
                                            </div>
                                            <IonButton onClick={() => setDate((d) => {
                                                return new Date(d.setDate(d.getDate() + 7));
                                            })}>
                                                <IonIcon icon={arrowForward} />
                                            </IonButton>
                                        </div>
                                        <br />
                                        {orderHistory.length > 0 ?
                                            <OrderHistory
                                                orderHistory={orderHistory}
                                                orderHistoryLoading={orderHistoryLoading}
                                                date={date}
                                            />
                                        : (
                                            <p style={{ margin: "15px 0" }}><b>Nem található rendelés</b></p>
                                        )}
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                ) : (
                    <IonCard className="centerCard">
                        <IonCardHeader>
                            <IonCardTitle>Jelentkezzen be</IonCardTitle>
                            <IonCardSubtitle>
                                A rendelések megtekintéséhez kérem regisztráljon
                                vagy jelentkezzen be!
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonButton
                                expand="block"
                                onClick={onOpenLoginModal}
                            >
                                Bejelentkezés
                            </IonButton>
                            <IonButton
                                expand="block"
                                color="medium"
                                onClick={onOpenRegModal}
                            >
                                Regiszráció
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                )}
            </PageWrapper>
        </div>
    );
};

export default SummedOrdersPage;
