import React from "react";
import { IonSpinner } from "@ionic/react";
import "./SuspenseLoader.scss";

interface IProps {
  loading: boolean;
  children?: any;
}

const SuspenseLoader: React.FC<IProps> = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <div className="suspense-loader">
          <IonSpinner name="crescent" className="suspense-loader-spinner" />
          <span className="suspense-loader-text">Kérem várjon...</span>
        </div>
      ) : (
        <>{children ?? ""}</>
      )}
    </>
  );
};

export default SuspenseLoader;
