import { ModelCategory } from "./CategoryModel";

export interface ModelCartItem {
  quantity: number;
  menu_item: ModelMenuItem;
  date: Date;
  id: number;
  category: {
    name: string;
    background_color: string;
    font_color: string;
    weekly_menu_of_category_id: number | null;
    code: string;
    watermark_id: number | null;
    big_portion_category_id: number | null;
  };
}

export interface ModelCart {
  items: ModelCartItem[];
}

export interface ModelImage {
  id: number;
  uuid: string;
  url?: string;
}

export interface ModelMenuIcon {
  id: number;
  name: string;
  open_url: string | null;
  tooltip: string | null;
  position: string | null;
  image: ModelImage | null;
}

export interface ModelFood {
  id: number;
  name: string;
  display_name: string;
  image_id: number | null;
  food_rating_cache: number | null;
  rating_count: number | null;
  is_favourite: boolean;
}

export interface ModelMenuItem {
  id: number;
  food: ModelFood;
  price: number;
  price_without_discount: string | null;
  date: Date;
  order_disabled_at: Date | null;
  order_disabled_quota_at: Date | null;
  description: string | null;
  weekly_name: string | null;
  vat: number | null;
  icons: Array<ModelMenuIcon> | null;

  energy_portion_food_one: number | null;
  fat_portion_food_one: number | null;
  saturated_fat_portion_food_one: number | null;
  carb_portion_food_one: number | null;
  sugar_portion_food_one: number | null;
  protein_portion_food_one: number | null;
  salt_portion_food_one: number | null;
  energy_hundred_food_one: number | null;
  fat_hundred_food_one: number | null;
  saturated_fat_hundred_food_one: number | null;
  carb_hundred_food_one: number | null;
  sugar_hundred_food_one: number | null;
  protein_hundred_food_one: number | null;
  salt_hundred_food_one: number | null;
  vat_food_one: number | null;
  weight_food_one: number | null;
  food_name_one: string | null;

  energy_portion_food_two: number | null;
  fat_portion_food_two: number | null;
  saturated_fat_portion_food_two: number | null;
  carb_portion_food_two: number | null;
  sugar_portion_food_two: number | null;
  protein_portion_food_two: number | null;
  salt_portion_food_two: number | null;
  energy_hundred_food_two: number | null;
  fat_hundred_food_two: number | null;
  saturated_fat_hundred_food_two: number | null;
  carb_hundred_food_two: number | null;
  sugar_hundred_food_two: number | null;
  protein_hundred_food_two: number | null;
  salt_hundred_food_two: number | null;
  vat_food_two: number | null;
  weight_food_two: number | null;
  food_name_two: string | null;

  energy_portion_food_three: number | null;
  fat_portion_food_three: number | null;
  saturated_fat_portion_food_three: number | null;
  carb_portion_food_three: number | null;
  sugar_portion_food_three: number | null;
  protein_portion_food_three: number | null;
  salt_portion_food_three: number | null;
  energy_hundred_food_three: number | null;
  fat_hundred_food_three: number | null;
  saturated_fat_hundred_food_three: number | null;
  carb_hundred_food_three: number | null;
  sugar_hundred_food_three: number | null;
  protein_hundred_food_three: number | null;
  salt_hundred_food_three: number | null;
  vat_food_three: number | null;
  weight_food_three: number | null;
  food_name_three: string | null;

  category: ModelCategory | null;
  type: string;
  comment: string | null;

  disabled?: boolean;
  is_last_minute_orderable?: boolean;
  cancel_deadline?: string;
}

export interface ModelBanner {
  id: number;
  name: string;
  category_id: number | null;
  open_url: string;
  image: ModelImage | null;
  mobile_image: ModelImage | null;
  full_width: boolean;
  frontend_title: string | null;
  frontend_text: string | null;
  font_color: string;
  bg_color: string;
  show_on_mobile: boolean;
}

export interface ModelFilter {
  id: number;
  name: string;
  enabled: "enabled" | "disabled" | "default";
  sequence: number;
}

export interface ModelCalorie {
  lower: number;
  upper: number;
}

export const DEFAULT_CALORIE = {
  lower: 0,
  upper: 2000,
} as ModelCalorie;

export const DEFAULT_CARB = {
  lower: 0,
  upper: 200,
} as ModelCalorie;
