import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonInput,
    IonText,
} from "@ionic/react";
import { alertCircleOutline, eyeOutline } from "ionicons/icons";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../RootReducer";
import { setLoginModal } from "./Actions";
import { addLoading, removeLoading } from "../../loading/Actions";
import { addToast } from "../../toast/Actions";
import { setLostPasswordModal } from "../lostpassword/Actions";
import { setRegistrationModal } from "../registration/Actions";

import useFormValidation from "../../../helpers/form/FormValidationHelper";
import ValidateLogin from "../../../helpers/validators/ValidateLogin";
import {
    addApiErrors,
    hasError,
    setInitError,
    ErrorsList,
    handleApiError,
    handleApiResponseError,
} from "../../../helpers/form/GlobalHelpers";
import { login } from "../../../services/UserService";

import ModalWithImage from "../../../components/modals/ModalWithImage";
import InlineAlert from "../../../components/InlineAlert";

type Props = {
    onLogin: (login: boolean, token: string) => void;
};

const INIT_STATE = {
    email: "",
    password: "",
};

const LoginModal: React.FC<Props> = ({ onLogin }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const loginModalFromReducer = useSelector(
        (state: RootState) => state.LoginModalReducer.showModal
    );
    useEffect(() => {
        setShow(loginModalFromReducer);

        return () => {
            setShow(false);
        };
    }, [loginModalFromReducer]);

    const { handleChange, values } = useFormValidation(INIT_STATE);

    const [status, setStatus] = useState({
        error_list: INIT_STATE,
    });
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    const handleLogin = async () => {
        dispatch(addLoading());
        setInitError(INIT_STATE, setStatus);

        const errors = ValidateLogin(values);
        if (hasError(errors)) {
            dispatch(removeLoading());
            addApiErrors(errors, setStatus);
        } else {
            login(values.email, values.password)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a bejelentkezés közben...",
                            setStatus
                        )
                    ) {
                        return;
                    }
                    onLogin(true, res.data.access_token);
                    dispatch(
                        addToast({
                            message: "Sikeres bejelentkezés",
                            duration: 3000,
                            color: "success",
                        })
                    );
                    dispatch(setLoginModal(false));
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a bejelentkezés közben...",
                        setStatus
                    );
                });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };

    const onCloseModal = () => {
        dispatch(setLoginModal(false));
    };

    const openLostPasswordModal = () => {
        dispatch(setLostPasswordModal(true));
    };

    const openRegistrationModal = () => {
        dispatch(setRegistrationModal(true));
    };

    return (
        <ModalWithImage
            title="Bejelentkezés"
            subtitle="Üdv újra a Cityfood weboldalán, kérem jelentkezzen be!"
            onCloseModal={onCloseModal}
            show={show}
            id="loginModal"
            overflow={true}
        >
            <div className="form">
                <form className="form" onSubmit={handleSubmit}>
                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.email
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">E-mail cím</IonLabel>
                        <IonInput
                            title="E-mail cím"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="email"
                            type="email"
                            inputmode="email"
                            pattern="email"
                            name="email"
                            value={values.email}
                            required
                            autofocus={true}
                            data-testid="input-login-email"
                        ></IonInput>
                    </IonItem>

                    <IonItem
                        lines="none"
                        className={
                            "rounded-input small-input " +
                            (Boolean(status.error_list) &&
                                status.error_list.password
                                ? "error"
                                : "")
                        }
                    >
                        <IonLabel position="floating">Jelszó</IonLabel>
                        <IonInput
                            title="Jelszó"
                            onIonInput={handleChange}
                            onKeyDown={handleKeyDown}
                            clearOnEdit={false}
                            autocomplete="current-password"
                            type={showPassword ? "text" : "password"}
                            pattern="password"
                            name="password"
                            value={values.password}
                            required
                            data-testid="input-login-password"
                        ></IonInput>
                        <IonButton
                            slot="end"
                            color="secondary"
                            fill={showPassword ? "outline" : "solid"}
                            onClick={() =>
                                setShowPassword((prevState) => !prevState)
                            }
                            tabIndex={-1}
                        >
                            <IonIcon slot="icon-only" icon={eyeOutline} />
                        </IonButton>
                    </IonItem>

                    <ErrorsList errors={status.error_list} />

                    <div className="ion-text-center">
                        <IonButton
                            id="loginButton"
                            size="large"
                            expand="block"
                            onClick={handleLogin}
                        >
                            Bejelentkezés
                        </IonButton>
                    </div>
                    <div className="ion-text-center" id="mobile_reg">
                        <IonText color="medium">
                            VAGY
                        </IonText>
                        <IonButton
                            size="large"
                            expand="block"
                            onClick={openRegistrationModal}
                            color="medium"
                        >
                            Még nincs fiókom, regisztrálom
                        </IonButton>
                    </div>
                </form>
            </div>

            <IonRow className="modal-footer">
                <IonCol size="6">
                    <a
                        onClick={openLostPasswordModal}
                        style={{ cursor: "pointer" }}
                    >
                        Elfelejtett jelszó
                    </a>
                </IonCol>
                <IonCol size="6">
                    <a
                        onClick={openRegistrationModal}
                        style={{ cursor: "pointer" }}
                    >
                        Még nincs fiókom, regisztrálom
                    </a>
                </IonCol>
            </IonRow>
        </ModalWithImage>
    );
};

export default LoginModal;
