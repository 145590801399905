export const sendBarionPixel = (type, data) => {
    try {
        // eslint-disable-next-line no-undef
        bp("track", type, data);
    } catch (err) {
        console.error(err);
    }
};

export const PixelConsent = (consent) => {
    // eslint-disable-next-line no-undef
    bp("consent", consent ? "grantConsent" : "rejectConsent");
};

export const sendFacebookPixel = (type, data) => {
    try {
        // eslint-disable-next-line no-undef
        fbq("track", type, data);
    } catch (err) {
        console.error(err);
    }
};

// const contentViewProperties = {
//     'contentType': 'Product',
//     'currency': 'HUF',
//     'id': 'item_42',
//     'name': "Hitchhikers' Guide to the Galaxy, hardcover, ISBN: 9780575115347",
//     'quantity': 10.0,
//     'unit': 'pcs.',
//     'unitPrice': 4990.0,
//     'brand': 'Gollancz',
//     'category': 'books|hardcover|scifi, books|hardcover|comedy',
//     'customerValue': 150.0,
//     'ean': '9780575115347',
//     'imageUrl': 'https://images-na.ssl-images-amazon.com/images/I/51MzUz8rQcL._SX305_BO1,204,203,200_.jpg',
//     'list': 'ProductPage',
//     'variant': 'hardcover'
// }
