import React, { useState } from "react";

import "./OrderHistory.scss";
import moment from "moment";
import { canModifyOrder, canModifyOrderItem } from "../../helpers/functions/OrderPageHelpers";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { add, remove } from "ionicons/icons";
import { clamp } from "../../helpers/functions/CartHelper";
import { changeOrder } from "../../services/CartService";

interface IProps {
  orderHistory: IOrderData[];
  orderHistoryLoading: boolean;
  date: Date;
}

const OrderHistory: React.FC<IProps> = ({ orderHistory, orderHistoryLoading, date }) => {
  const [modifiedQuantitiy, setModifiedQuantitiy] = useState<ICartItem[]>([]);

  const getItemQuantity = (item: ICartItem) => {
    const find = modifiedQuantitiy.find((i) => i.id === item.id);
    let quantity = item.quantity;
    if (find) {
      quantity = find.quantity;
    }
    return quantity;
  };

  const getItemPrice = (item: ICartItem, order: IOrderData, withQuantity: boolean = true) => {
    const find = modifiedQuantitiy.find((i) => i.id === item.id);
    let quantity = item.quantity;
    if (find) {
      quantity = find.quantity;
    }

    let price = item.menu_item.price ?? 0;

    // Ha ez egy heti menü akkor más ár kell, itt össze kell adni az összes heti menü item árát...
    if (item.menu_item.menu_category.weekly_menu_of_category_id !== null) {
      price = order.cart.cart_items.reduce((price, i) => {
        if (i.menu_item.menu_category.code !== item.menu_item.menu_category.code) {
          return price;
        }
        return price + item.menu_item.price;
      }, 0);
    }

    if (withQuantity) {
      return price * quantity;
    }

    return price;
  };

  const handleChangeAmount = (items: ICartItem[], plus: boolean) => {
    let copy = [...modifiedQuantitiy];
    for (const item of items) {
      const findIndex = copy.findIndex((i) => i.id === item.id);
      if (plus) {
        if (findIndex == -1) {
          // Ha hozzá akar adni de még nincs a módosított tömben akkor ne enegdjük hiszen akkor többet akar majd mint az eredeti mennyiség
          continue;
        } else {
          const newQuantity = copy[findIndex].quantity + 1;
          if (newQuantity === item.original_amount) {
            copy.splice(findIndex, 1);
            continue;
          }
          copy[findIndex].quantity = clamp(newQuantity, 0, item.previous_quantity);
        }
      } else {
        if (findIndex === -1) {
          copy.push({
            ...item,
            quantity: clamp(item.quantity - 1, 0, 999),
          });
          continue;
        } else {
          copy[findIndex].quantity = clamp(copy[findIndex].quantity - 1, 0, 999);
        }
      }
    }
    setModifiedQuantitiy(copy);
  };

  const handleCancelAll = async () => {
    let copy = [...modifiedQuantitiy];
    for (const order of orderHistory) {
      const items = order.cart.cart_items.filter((item) => {
        const week = moment(item.menu_item.date).week();
        return week === moment(date).week();
      });
      for (const item of items) {
        const findIndex = modifiedQuantitiy.findIndex((i) => i.id === item.id);
        if (findIndex === -1) {
          copy.push({
            ...item,
            quantity: 0,
          });
        } else {
          copy[findIndex].quantity = 0;
        }
      }
    }
    setModifiedQuantitiy(copy);

    if (copy.length > 0) {
      let carts = [];
      for (const copyItem of copy) {
        if (!carts[copyItem.cart_id]) {
          carts[copyItem.cart_id] = [
            {
              id: copyItem.id,
              amount: 0,
            },
          ];
        } else {
          carts[copyItem.cart_id].push({
            id: copyItem.id,
            amount: 0,
          });
        }
      }

      if (carts.length > 0) {
        for (const key in carts) {
          const change = await changeOrder({
            cart_id: key,
            cart_items: carts[key],
          });
        }
      }
    }
  };

  const handleCancelSome = async () => {
    if (modifiedQuantitiy.length > 0) {
      let carts = [];
      for (const copyItem of modifiedQuantitiy) {
        if (!carts[copyItem.cart_id]) {
          carts[copyItem.cart_id] = [
            {
              id: copyItem.id,
              amount: 0,
            },
          ];
        } else {
          carts[copyItem.cart_id].push({
            id: copyItem.id,
            amount: 0,
          });
        }
      }

      if (carts.length > 0) {
        for (const key in carts) {
          const change = await changeOrder({
            cart_id: key,
            cart_items: carts[key],
          });
        }
      }
    }
  };

  return (
    <>
      <table className="order-table">
        <thead>
          <tr>
            <th>Nap</th>
            <th>Étel neve</th>
            <th>Ár</th>
            <th>Db</th>
            <th>Összesen</th>
          </tr>
        </thead>
        <tbody>
          {orderHistory.map((order) => (
            <React.Fragment key={order.id}>
              <tr>
                <td colSpan={6} className="order-table-id-row">
                  {order.id}#{order.shipping_location.msoft_id}
                  {order.person ?
                    <>
                      {" - "}{order.person.name}
                    </>
                  : null}
                </td>
              </tr>

              {/* Heti menüs cucc / fájdalom, szenvedés */}
              {order.cart.cart_items
                .filter((item) => {
                  const week = moment(item.menu_item.date).week();
                  return week === moment(date).week();
                })
                .filter((item) => {
                  return item.menu_item.menu_category.weekly_menu_of_category_id !== null;
                })
                .reduce((items, item) => {
                  const find = items.find((i: ICartItem) => i.menu_item.menu_category.code === item.menu_item.menu_category.code);
                  if (find) {
                    return items;
                  }
                  return [...items, item];
                }, [])
                .map((item) => (
                  <tr key={item.id}>
                    <td>{moment(item.menu_item.date).format("YYYY.MM.DD. - dddd")}</td>
                    <td>{`${item.menu_item.menu_category.code} ${item.menu_item.food.name}`}</td>
                    <td>{getItemPrice(item, order, false)} Ft</td>
                    <td className="orderChangeButtons">
                      {canModifyOrderItem(item, order) ? (
                        <button
                          onClick={() =>
                            handleChangeAmount(
                              order.cart.cart_items
                                .filter((i) => {
                                  const week = moment(i.menu_item.date).week();
                                  return week === moment(date).week();
                                })
                                .filter((i) => {
                                  return item.menu_item.menu_category.code === i.menu_item.menu_category.code;
                                }),
                              false
                            )
                          }
                          className="btn btn-medium btn-sm"
                        >
                          <IonIcon icon={remove} />
                        </button>
                      ) : null}
                      <span className="Quantity">{getItemQuantity(item)}</span>
                      {canModifyOrderItem(item, order) ? (
                        <button
                          onClick={() =>
                            handleChangeAmount(
                              order.cart.cart_items
                                .filter((i) => {
                                  const week = moment(i.menu_item.date).week();
                                  return week === moment(date).week();
                                })
                                .filter((i) => {
                                  return item.menu_item.menu_category.code === i.menu_item.menu_category.code;
                                }),
                              true
                            )
                          }
                          className="btn btn-medium btn-sm"
                        >
                          <IonIcon icon={add} />
                        </button>
                      ) : null}
                    </td>
                    <td>{getItemPrice(item, order, true)} Ft</td>
                  </tr>
                ))}

              {/* Sima, azaz nem heti menüs cucc */}
              {order.cart.cart_items
                .filter((item) => {
                  const week = moment(item.menu_item.date).week();
                  return week === moment(date).week();
                })
                .filter((item) => {
                  return item.menu_item.menu_category.weekly_menu_of_category_id === null;
                })
                .map((item) => (
                  <tr key={item.id}>
                    <td>{moment(item.menu_item.date).format("YYYY.MM.DD. - dddd")}</td>
                    <td>{`${item.menu_item.menu_category.code} ${item.menu_item.food.name}`}</td>
                    <td>{getItemPrice(item, order, false)} Ft</td>
                    <td className="orderChangeButtons">
                      {canModifyOrderItem(item, order) ? (
                        <button onClick={() => handleChangeAmount([item], false)} className="btn btn-medium btn-sm">
                          <IonIcon icon={remove} />
                        </button>
                      ) : null}
                      <span className="Quantity">{getItemQuantity(item)}</span>
                      {canModifyOrderItem(item, order) ? (
                        <button onClick={() => handleChangeAmount([item], true)} className="btn btn-medium btn-sm">
                          <IonIcon icon={add} />
                        </button>
                      ) : null}
                    </td>
                    <td>{getItemPrice(item, order, true)} Ft</td>
                  </tr>
                ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonButton color="medium" routerDirection="root" routerLink="/" expand="block">
              Vissza az étlapra
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton color="medium" expand="block" onClick={handleCancelAll}>
              Teljes hét lemondása
            </IonButton>
          </IonCol>
          <IonCol>
            <IonButton color="danger" disabled={modifiedQuantitiy.length === 0} onClick={handleCancelSome} expand="block">
              Módosítások mentése
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default OrderHistory;
