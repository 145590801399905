import moment from "moment";
import { HttpClient } from "../helpers/HttpClient";
import { ModelFood, ModelImage, ModelMenuIcon, ModelMenuItem } from "../models/FoodModel";
import { ModelOrderData, ModelServiceArea, ModelServiceLocation, ModelShippingLocation } from "../models/ShippingModel";
import Axios from "axios";
import { ModelPerson } from "../models/Persons";

export const getShippingLocations = async () => {
  return await HttpClient({
    url: "api/v1/shipping-locations",
    method: "get",
  });
};

export const processShippingLocations = (data: any) => {
  try {
    let tempShippingLocations = new Array<ModelShippingLocation>();
    Object.values(data).map((location: any) => {
      var tempServiceLocation = null;
      if (location.service_location) {
        tempServiceLocation = {
          id: location.service_location.id,
          name: location.service_location.name,
          shipping_route_id: location.service_location.shipping_route_id,
          service_area_id: location.service_location.service_area_id,
          service_area: {
            id: location.service_location.service_area.id,
            postal_code: location.service_location.service_area.postal_code,
            city_name: location.service_location.service_area.city_name,
            is_archived: location.service_location.service_area.is_archived,
          } as ModelServiceArea,
        } as ModelServiceLocation;
      }

      tempShippingLocations.push({
        id: location.id,
        user_id: location.user_id,
        is_company: Boolean(location.is_company === 1),
        tax_number: location.tax_number,
        clerk_name: location.clerk_name,
        service_location_not_found: Boolean(location.service_location_not_found === 1),
        service_location_comment: location.service_location_comment,
        shipping_name: location.shipping_name,
        shipping_address: location.shipping_address,
        shipping_gate_phone: location.shipping_gate_phone,
        shipping_phone_number: location.shipping_phone_number,
        shipping_comment: location.shipping_comment,
        is_billing_equals_to_shipping: Boolean(location.is_billing_equals_to_shipping === 1),
        billing_name: location.billing_name,
        billing_postal_code: location.billing_postal_code,
        billing_city: location.billing_city,
        billing_street: location.billing_street,
        billing_address: location.billing_address,
        user_approved: Boolean(location.user_approved === 1),
        payment_approved: Boolean(location.payment_approved === 1),
        full_shipping_address: location.full_shipping_address,
        billing_name_for_invoice: location.billing_name_for_invoice,
        billing_postal_code_for_invoice: location.billing_postal_code_for_invoice,
        billing_city_for_invoice: location.billing_city_for_invoice,
        billing_street_for_invoice: location.billing_street_for_invoice,
        billing_address_for_invoice: location.billing_address_for_invoice,
        service_location_id: location.service_location_id,
        service_location: tempServiceLocation,
        old_address_imported_at: location.old_address_imported_at ?? null,
        old_city: location.old_city ?? null,
        old_postal_code: location.old_postal_code ?? null,
        old_street: location.old_street ?? null,
        msoft_id: location.msoft_id ?? null,
      });
    });
    return tempShippingLocations;
  } catch (e) {
    console.error(e);
  }
};

export const getPaymentMethods = async (shipping_location_id: number) => {
  return await HttpClient({
    url: "api/v1/payment-methods",
    method: "get",
    params: {
      shipping_location_id,
    },
  });
};

let cancelGetTotalToken: any;
export const getTotal = async (shipping_location_id: number, coupon_code: string) => {
  if (typeof cancelGetTotalToken != typeof undefined) {
    cancelGetTotalToken.cancel("REQ_CANCEL");
  }

  cancelGetTotalToken = Axios.CancelToken.source();

  return await HttpClient({
    url: "api/v1/total",
    method: "get",
    params: {
      shipping_location_id: shipping_location_id,
      coupon_code: coupon_code,
    },
    cancelToken: cancelGetTotalToken.token,
  });
};

export const postStartPayment = async (shipping_location_id: number | null, payment_method: string, redirect_url: string, coupon_code: string | null, order_id?: number | null, person_id?: number | null) => {
  if (shipping_location_id !== null) {
    return await HttpClient({
      url: "api/v1/start-payment",
      method: "post",
      timeout: 60000,
      data: {
        shipping_location_id,
        payment_method,
        redirect_url,
        coupon_code,
        person_id,
      },
    });
  } else {
    return await HttpClient({
      url: "api/v1/start-payment",
      method: "post",
      timeout: 60000,
      data: {
        payment_method,
        redirect_url,
        order_id,
        person_id,
      },
    });
  }
};

export const getOrders = async (year: number, month: number, person_id?: string) => {
  return await HttpClient({
    url: `api/v1/orders`,
    method: "get",
    params: {
      year,
      month,
      person_id,
    },
  });
};

export const getOrder = async (order_id?: string) => {
  return await HttpClient({
    url: `api/v1/orders`,
    method: "get",
    params: {
      order_id,
    },
  });
};

export const getLastOrder = async (payment_id: string) => {
  return await HttpClient({
    url: "api/v1/last-order",
    method: "get",
    params: {
      payment_id,
    },
  });
};

export const processCart = (cart: any) => {
  let tempAddCartItems: any = [];
  if (cart !== undefined && cart.cart_items !== undefined && cart.cart_items.length > 0) {
    cart.cart_items.map((item: any, _: number) => {
      let quantity = item.quantity;
      const cart_item_id = item.id;
      item = item.menu_item;

      let tempFood = {
        id: item.food.id,
        name: item.food.name,
        display_name: item.food.display_name,
        image_id: item.food.image_id,
        food_rating_cache: item.food.food_rating_cache,
        is_favourite: false,
      } as ModelFood;

      // Ha van az ételhez ikon
      let tempIcons: Array<ModelMenuIcon> = [];

      let tempMenuItem = {
        id: item.id,
        food: tempFood,
        price: item.price,
        date: item.date,
        order_disabled_at: item.order_disabled_at,
        order_disabled_quota_at: item.order_disabled_quota_at ?? null,
        disabled: item.disabled, // új mező
        is_last_minute_orderable: item.is_last_minute_orderable, // új mező
        description: item.description,
        vat: item.vat,
        icons: tempIcons,

        energy_portion_food_one: item.energy_portion_food_one,
        fat_portion_food_one: item.fat_portion_food_one,
        saturated_fat_portion_food_one: item.saturated_fat_portion_food_one,
        carb_portion_food_one: item.carb_portion_food_one,
        sugar_portion_food_one: item.sugar_portion_food_one,
        protein_portion_food_one: item.protein_portion_food_one,
        salt_portion_food_one: item.salt_portion_food_one,
        energy_hundred_food_one: item.energy_hundred_food_one,
        fat_hundred_food_one: item.fat_hundred_food_one,
        saturated_fat_hundred_food_one: item.saturated_fat_hundred_food_one,
        carb_hundred_food_one: item.carb_hundred_food_one,
        sugar_hundred_food_one: item.sugar_hundred_food_one,
        protein_hundred_food_one: item.protein_hundred_food_one,
        salt_hundred_food_one: item.salt_hundred_food_one,
        vat_food_one: item.vat_food_one,
        weight_food_one: item.weight_food_one,
        food_name_one: item.food_name_one,

        energy_portion_food_two: item.energy_portion_food_two,
        fat_portion_food_two: item.fat_portion_food_two,
        saturated_fat_portion_food_two: item.saturated_fat_portion_food_two,
        carb_portion_food_two: item.carb_portion_food_two,
        sugar_portion_food_two: item.sugar_portion_food_two,
        protein_portion_food_two: item.protein_portion_food_two,
        salt_portion_food_two: item.salt_portion_food_two,
        energy_hundred_food_two: item.energy_hundred_food_two,
        fat_hundred_food_two: item.fat_hundred_food_two,
        saturated_fat_hundred_food_two: item.saturated_fat_hundred_food_two,
        carb_hundred_food_two: item.carb_hundred_food_two,
        sugar_hundred_food_two: item.sugar_hundred_food_two,
        protein_hundred_food_two: item.protein_hundred_food_two,
        salt_hundred_food_two: item.salt_hundred_food_two,
        vat_food_two: item.vat_food_two,
        weight_food_two: item.weight_food_two,
        food_name_two: item.food_name_two,

        energy_portion_food_three: item.energy_portion_food_three,
        fat_portion_food_three: item.fat_portion_food_three,
        saturated_fat_portion_food_three: item.saturated_fat_portion_food_three,
        carb_portion_food_three: item.carb_portion_food_three,
        sugar_portion_food_three: item.sugar_portion_food_three,
        protein_portion_food_three: item.protein_portion_food_three,
        salt_portion_food_three: item.salt_portion_food_three,
        energy_hundred_food_three: item.energy_hundred_food_three,
        fat_hundred_food_three: item.fat_hundred_food_three,
        saturated_fat_hundred_food_three: item.saturated_fat_hundred_food_three,
        carb_hundred_food_three: item.carb_hundred_food_three,
        sugar_hundred_food_three: item.sugar_hundred_food_three,
        protein_hundred_food_three: item.protein_hundred_food_three,
        salt_hundred_food_three: item.salt_hundred_food_three,
        vat_food_three: item.vat_food_three,
        weight_food_three: item.weight_food_three,
        food_name_three: item.food_name_three,
        category: item.menu_category,
        comment: item.comment ?? null,
        cancel_deadline: item.cancel_deadline ?? null,
      } as ModelMenuItem;

      let tempCategory = item.menu_category
        ? {
            name: item.menu_category.name,
            weekly_menu_of_category_id: item.menu_category.weekly_menu_of_category_id,
            font_color: item.menu_category.font_color,
            background_color: item.menu_category.background_color,
          }
        : {};

      tempAddCartItems.push({
        date: moment(item.date).toDate(),
        menu_item: tempMenuItem,
        category: tempCategory,
        quantity: quantity,
        id: cart_item_id,
      });
    });
  }
  return tempAddCartItems;
};

export const processOrderData = (data: any) => {
  let tempOrderData = new Array<ModelOrderData>();
  try {
    Object.values(data).map((orderData: any) => {
      var tempServiceLocation = null;
      if (orderData.service_location !== null && orderData.service_location !== undefined) {
        tempServiceLocation = {
          id: orderData.service_location.id,
          name: orderData.service_location.name,
          shipping_route_id: orderData.service_location.shipping_route_id,
          service_area_id: orderData.service_location.service_area_id,
          service_area: {
            id: orderData.service_location.service_area.id,
            postal_code: orderData.service_location.service_area.postal_code,
            city_name: orderData.service_location.service_area.city_name,
            is_archived: orderData.service_location.service_area.is_archived,
          } as ModelServiceArea,
        } as ModelServiceLocation;
      }

      tempOrderData.push({
        id: orderData.id,
        is_company: orderData.is_company,
        tax_number: orderData.tax_number,
        shipping_fee_percent: orderData.shipping_fee_percent,
        shipping_name: orderData.shipping_name,
        service_location_id: orderData.service_location_id,
        service_location_not_found: orderData.service_location_not_found,
        service_location_comment: orderData.service_location_comment,
        shipping_address: orderData.shipping_address,
        shipping_gate_phone: orderData.shipping_gate_phone,
        shipping_phone_number: orderData.shipping_phone_number,
        shipping_comment: orderData.shipping_comment,
        is_billing_equals_to_shipping: Boolean(orderData.is_billing_equals_to_shipping === 1),
        billing_name: orderData.billing_name,
        billing_postal_code: orderData.billing_postal_code,
        billing_city: orderData.billing_city,
        billing_street: orderData.billing_street,
        billing_address: orderData.billing_address,
        payment_method: orderData.payment_method,
        payment_status: orderData.payment_status,
        cart_id: orderData.cart_id,
        discount: orderData.discount,
        total: orderData.total,
        real_total: orderData.real_total,
        dealer_id: orderData.dealer_id,
        service_location: tempServiceLocation,
        cart: {
          items: processCart(orderData.cart),
        },
        shipping_location_id: orderData.shipping_location_id ?? null,
        gateway_url: data.gateway_url ?? null,
        created_at: orderData.created_at ?? null,
        coupon: orderData.coupon ?? null,
        invoices: orderData.invoices ?? [],
        msoft_id: orderData.msoft_id ?? undefined,
        person_id: orderData.person_id ?? undefined,
        person: orderData.person ?? undefined,
        original_dealer_total: orderData.original_dealer_total,
        original_packed_food_total: orderData.original_packed_food_total,
        original_total: orderData.original_total,
      });
    });
  } catch (e) {
    console.error(e);
  }
  return tempOrderData;
};

export const getServiceAreas = async () => {
  return await HttpClient({
    url: "api/v1/areas",
    method: "get",
  });
};

export const getServiceLocations = async (service_area_id: number) => {
  return await HttpClient({
    url: "api/v1/locations",
    method: "get",
    params: {
      service_area_id,
    },
  });
};

export const saveShippingAddress = async (data: any) => {
  return await HttpClient({
    url: "api/v1/shipping-locations",
    method: "post",
    data: data,
  });
};

export const deleteShippingAddress = async (shipping_location_id: number) => {
  return await HttpClient({
    url: "api/v1/delete-shipping-location",
    method: "post",
    data: { shipping_location_id },
  });
};

export const checkCoupon = async (coupon_code: string, shipping_location_id: number) => {
  return await HttpClient({
    url: "api/v1/coupon-check",
    method: "get",
    params: {
      coupon_code,
      shipping_location_id,
    },
  });
};

export const getOrderByGeneratedId = async (generated_id: string) => {
  return await HttpClient({
    url: "api/v1/order-by-generated-id",
    method: "get",
    params: {
      generated_id,
    },
  });
};

export const getOverlapOrder = async () => {
  return await HttpClient({
    url: "api/v1/order-overlap",
    method: "get",
  });
};

export const getCancellableOrders = async () => {
  return await HttpClient({
    url: `api/v1/cancellable-orders`,
    method: "get",
  });
};

export const getOrdersSummed = async (year: number, week: number) => {
  return await HttpClient({
    url: `api/v1/orders-for-week`,
    method: "get",
    params: {
      year,
      week,
    },
  });
};

export const getDeliveryAreas = async () => {
  return await HttpClient({
    url: "api/v1/delivery-areas",
    method: "get",
  });
};
