import React from "react";
import ReactDOM from "react-dom";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Plugins } from "@capacitor/core";
import { setupConfig } from "@ionic/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";

import { IonReactRouter } from "@ionic/react-router";

import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";

import { RootReducer } from "./store/RootReducer";
import GlobalAlert from "./store/alert/GlobalAlert";
import GlobalToast from "./store/toast/GlobalToast";
import GlobalLoading from "./store/loading/GlobalLoading";
import ErrorBoundary from "./helpers/ErrorBoundary";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

const { SplashScreen } = Plugins;

setupConfig({
  mode: "ios",
  animated: false,
  hardwareBackButton: false,
});

//TODO: Ha kijön az ionic 5.0.8 vagy újabb akkor ezt ki lehet venni.
window.addEventListener(
  "keyboardDidShow",
  (e) => {
    window.dispatchEvent(new Event("resize")); //temporary scroll-assist fix
  },
  false
);

const store = createStore(RootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));

ReactDOM.render(
  <IonReactRouter>
    <Provider store={store}>
      <GlobalAlert />
      <GlobalToast />
      <GlobalLoading />
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </IonReactRouter>,
  document.getElementById("root")
);
SplashScreen.hide();

defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
