import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonGrid,
    IonCol,
    IonRow,
} from "@ionic/react";
import { useAuth } from "../context/AuthContext";

import { useDispatch } from "react-redux";
import Header from "../layout/Header";

import { setLoginModal } from "../store/modals/login/Actions";
import { setRegistrationModal } from "../store/modals/registration/Actions";
import {
    Modelinvoice,
    ModelOrderData,
    ModelPaymentMethod,
} from "../models/ShippingModel";
import { useParams } from "react-router-dom";
import { addLoading, removeLoading } from "../store/loading/Actions";
import {
    getLastOrder,
    getOrder,
    getPaymentMethods,
    postStartPayment,
    processOrderData,
} from "../services/ShippingService";
import { getPaymentStatusAlert } from "../helpers/functions/CheckoutHelper";
import PageWrapper from "../layout/PageWrapper";
import OrderInfo from "../components/OrderInfo";
import {
    handleApiError,
    handleApiResponseError,
} from "../helpers/form/GlobalHelpers";
import {
    sendBarionPixel,
    sendFacebookPixel,
} from "../helpers/barion/PixelHelper";
import PaymentMethodsCard from "../components/checkout/PaymentMethodsCard";
import { EMPTY_CART } from "../store/cart/ActionTypes";
import { ModelCartItem } from "../models/FoodModel";
import { SumCartItems } from "../helpers/functions/CartHelper";

const CheckoutCompletePage: React.FC = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams<{ orderId: string }>();
    const { authInfo } = useAuth();
    const [loggedIn, setLoggedIn] = useState<boolean>(authInfo.loggedIn);

    const [orderData, setOrderData] = useState<{
        orderDataLoaded: boolean;
        orderData: Array<ModelOrderData>;
    }>({
        orderDataLoaded: false,
        orderData: [],
    });

    const fetchOrderData = () => {
        if (orderId === undefined) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const paymentId = urlParams.get("paymentId");

            getLastOrder(paymentId)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a rendelés lekérdezésekor, kérjük próbálja újra később..."
                        )
                    ) {
                        setOrderData({ orderDataLoaded: true, orderData: [] });
                        return;
                    }
                    let tempOrderData = processOrderData([res.data.data]);
                    setOrderData({
                        orderDataLoaded: true,
                        orderData: tempOrderData,
                    });
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a rendelés lekérdezésekor, kérjük próbálja újra később..."
                    );
                    setOrderData({ orderDataLoaded: true, orderData: [] });
                });
        } else {
            getOrder(orderId)
                .then((res: any) => {
                    dispatch(removeLoading());
                    if (
                        handleApiResponseError(
                            dispatch,
                            res,
                            "Hiba történt a rendelés lekérdezésekor, kérjük próbálja újra később..."
                        )
                    ) {
                        setOrderData({ orderDataLoaded: true, orderData: [] });
                        return;
                    }
                    let tempOrderData = processOrderData(res.data.data);
                    setOrderData({
                        orderDataLoaded: true,
                        orderData: tempOrderData,
                    });
                })
                .catch((err: any) => {
                    handleApiError(
                        dispatch,
                        err,
                        "Hiba történt a rendelés lekérdezésekor, kérjük próbálja újra később..."
                    );
                    setOrderData({ orderDataLoaded: true, orderData: [] });
                });
        }
    };

    useEffect(() => {
        if (authInfo.loggedIn !== loggedIn) {
            setLoggedIn(authInfo.loggedIn);
        }
    }, [authInfo]);

    useEffect(() => {
        dispatch(addLoading());
        fetchOrderData();

        return () => {
            setOrderData({ orderDataLoaded: false, orderData: [] });
        };
    }, [orderId, loggedIn]);

    useEffect(() => {
        if (
            orderData.orderData !== undefined &&
            orderData.orderData.length > 0
        ) {
            if (orderData.orderData[0].payment_status === "order_in_progress") {
                const interval = setInterval(() => {
                    fetchOrderData();
                }, 10000);

                return () => {
                    clearInterval(interval);
                };
            } else if (orderData.orderData[0].payment_status === "order_paid") {
                sendBarionPixel("purchase", {
                    contents: orderData.orderData[0].cart.items.map(
                        (cartItem: ModelCartItem) => {
                            return {
                                contentType: "Product",
                                currency: "HUF",
                                id: cartItem.menu_item.id.toString(),
                                name:
                                    cartItem.menu_item?.food?.display_name ??
                                    cartItem.menu_item?.food?.name ??
                                    cartItem.menu_item.weekly_name,
                                quantity: cartItem.quantity,
                                totalItemPrice:
                                    cartItem.quantity *
                                    cartItem.menu_item.price,
                                unit: "pcs.",
                                unitPrice: cartItem.menu_item.price,
                            };
                        }
                    ),
                    currency: "HUF",
                    revenue: SumCartItems(orderData.orderData[0].cart.items),
                    step: "4",
                });

                // window.dataLayer.push({ ecommerce: null });
                // window.dataLayer.push({
                //     event: "purchase",
                //     ecommerce: {
                //         currency: "HUF",
                //         transaction_id: orderData.orderData[0].id ?? -1,
                //         value: SumCartItems(orderData.orderData[0].cart.items),
                //         items: orderData.orderData[0].cart.items.map(
                //             (cartItem: ModelCartItem) => {
                //                 return {
                //                     item_id: cartItem.menu_item.id,
                //                     item_name:
                //                         cartItem.menu_item?.food
                //                             ?.display_name ??
                //                         cartItem.menu_item.weekly_name,
                //                     quantity: cartItem.quantity,
                //                     price: cartItem.menu_item.price,
                //                     currency: "HUF",
                //                 };
                //             }
                //         ),
                //     },
                // });

                // window.dataLayer.push({
                //     ecommerce: {
                //         purchase: {
                //             actionField: {
                //                 id: orderData.orderData[0].id ?? -1,
                //                 revenue: SumCartItems(
                //                     orderData.orderData[0].cart.items
                //                 ),
                //                 affiliation: "rendel.cityfood.hu",
                //                 tax: "",
                //                 shipping: "",
                //                 coupon: "",
                //             },
                //             products: orderData.orderData[0].cart.items.map(
                //                 (cartItem: ModelCartItem) => {
                //                     return {
                //                         id: cartItem.menu_item.id,
                //                         name:
                //                             cartItem.menu_item?.food
                //                                 ?.display_name ??
                //                             cartItem.menu_item.weekly_name,
                //                         quantity: cartItem.quantity,
                //                         price: cartItem.menu_item.price,
                //                         brand: "CityFood",
                //                         category: "Food",
                //                         variant: "",
                //                         coupon: "",
                //                     };
                //                 }
                //             ),
                //         },
                //     },
                // });

                gtag("event", "purchase", {
                    transaction_id: orderData.orderData[0].id ?? -1,
                    value: SumCartItems(orderData.orderData[0].cart.items),
                    tax: 0,
                    shipping: 0,
                    currency: "HUF",
                    coupon: "",
                    items: [
                        orderData.orderData[0].cart.items.map(
                            (cartItem: ModelCartItem) => {
                                return {
                                    item_id: cartItem.menu_item.id,
                                    item_name:
                                        cartItem.menu_item?.food
                                            ?.display_name ??
                                        cartItem.menu_item?.food?.name ??
                                        cartItem.menu_item.weekly_name,
                                    affiliation: "CityFood",
                                    coupon: "",
                                    discount: 0,
                                    index: 0,
                                    item_brand: "CityFood",
                                    item_category: "Food",
                                    item_category2: "",
                                    item_category3: "",
                                    item_category4: "",
                                    item_category5: "",
                                    item_list_id: "",
                                    item_list_name: "",
                                    item_variant: "",
                                    location_id: "",
                                    quantity: cartItem.quantity,
                                    price: cartItem.menu_item.price,
                                };
                            }
                        ),
                    ],
                });
            } else if (
                orderData.orderData[0].payment_status ===
                    "order_unsuccessful" ||
                orderData.orderData[0].payment_status === "order_canceled"
            ) {
                sendBarionPixel("purchase", {
                    contents: orderData.orderData[0].cart.items.map(
                        (cartItem: ModelCartItem) => {
                            return {
                                contentType: "Product",
                                currency: "HUF",
                                id: cartItem.menu_item.id.toString(),
                                name:
                                    cartItem.menu_item?.food?.name ??
                                    cartItem.menu_item.weekly_name,
                                quantity: cartItem.quantity,
                                totalItemPrice:
                                    cartItem.quantity *
                                    cartItem.menu_item.price,
                                unit: "pcs.",
                                unitPrice: cartItem.menu_item.price,
                            };
                        }
                    ),
                    currency: "HUF",
                    revenue: SumCartItems(orderData.orderData[0].cart.items),
                    step: "-1",
                });
            }
        }
    }, [orderData]);

    const onOpenLoginModal = () => {
        dispatch(setLoginModal(true));
    };

    const onOpenRegModal = () => {
        dispatch(setRegistrationModal(true));
    };

    const handleOrderChangeOrCancel = () => {
        fetchOrderData();
    };

    if (authInfo.loggedIn === null || authInfo.loggedIn === false) {
        onOpenLoginModal();
    }

    const [paymentMethods, setPaymentMethods] = useState<{
        paymentMethodsLoaded: boolean;
        paymentMethods: Array<ModelPaymentMethod>;
    }>({
        paymentMethodsLoaded: false,
        paymentMethods: [],
    });
    const [selectedPaymentMethod, setSelectedPaymentMethod] =
        useState<string>("cash");
    const [aszf, setAszf] = useState<boolean>(false);

    const handleAszfChange = () => {
        setAszf((prevState) => !prevState);
    };

    const handleSelectedPaymentMethodChange = (value: any) => {
        setSelectedPaymentMethod(value);
    };

    const fetchPaymentMethods = (shipping_location_id: number) => {
        getPaymentMethods(shipping_location_id)
            .then((res: any) => {
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a fizetési módok lekérdezése közben, kérjük próbálja újra később..."
                    )
                ) {
                    setPaymentMethods({
                        paymentMethodsLoaded: true,
                        paymentMethods: [],
                    });
                    return;
                }

                let tempPaymentMethods = new Array<ModelPaymentMethod>();
                Object.values(res.data.data).map((method: any) => {
                    tempPaymentMethods.push({
                        name: method.name,
                        enabled: method.enabled,
                        message: method.message,
                    });
                });
                setPaymentMethods({
                    paymentMethodsLoaded: true,
                    paymentMethods: tempPaymentMethods,
                });
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a fizetési módok lekérdezése közben, kérjük próbálja újra később..."
                );
                setPaymentMethods({
                    paymentMethodsLoaded: true,
                    paymentMethods: [],
                });
            });
    };

    useEffect(() => {
        if (
            authInfo.loggedIn &&
            orderData.orderDataLoaded &&
            orderData.orderData.length
        ) {
            fetchPaymentMethods(orderData.orderData[0].shipping_location_id);
        } else {
            setPaymentMethods({
                paymentMethodsLoaded: true,
                paymentMethods: [],
            });
        }

        return () => {
            setPaymentMethods({
                paymentMethodsLoaded: false,
                paymentMethods: [],
            });
        };
    }, [authInfo, orderData]);

    const handleOrderComplete = async (order_id: number) => {
        dispatch(addLoading());

        postStartPayment(
            null,
            selectedPaymentMethod,
            process.env.REACT_APP_REDIRECT_URL,
            null,
            order_id
        )
            .then((res: any) => {
                dispatch(removeLoading());
                if (
                    handleApiResponseError(
                        dispatch,
                        res,
                        "Hiba történt a megrendelés leadásakor, kérjük próbálja újra később..."
                    )
                ) {
                    return;
                }

                dispatch({ type: EMPTY_CART });

                if (res.data.data.gateway_url !== null) {
                    window.location.href = res.data.data.gateway_url;
                } else {
                    fetchOrderData();
                }
            })
            .catch((err: any) => {
                handleApiError(
                    dispatch,
                    err,
                    "Hiba történt a megrendelés leadásakor, kérjük próbálja újra később..."
                );
            });
    };

    return (
        <div>
            <Header />

            <PageWrapper smallContainer={true}>
                {authInfo.loggedIn ? (
                    <>
                        {orderData.orderDataLoaded && (
                            <>
                                {orderData.orderData.length > 0 ? (
                                    <IonGrid className="marginInner">
                                        <IonRow className="ion-text-left">
                                            <IonCol size="12">
                                                {orderData.orderData[0]
                                                    .payment_status !==
                                                    "order_in_progress" &&
                                                    orderData.orderData[0]
                                                        .payment_status !==
                                                        "order_fully_revoked" &&
                                                    orderData.orderData[0]
                                                        .payment_status !==
                                                        "order_paid" && (
                                                        <>
                                                            <PaymentMethodsCard
                                                                selectedShippingLocation={
                                                                    null
                                                                }
                                                                paymentMethods={
                                                                    paymentMethods
                                                                }
                                                                selectedPaymentMethod={
                                                                    selectedPaymentMethod
                                                                }
                                                                aszf={aszf}
                                                                cartItemsByWeek={
                                                                    null
                                                                }
                                                                cartItemsByWeekLength={
                                                                    1
                                                                }
                                                                handleAszfChange={
                                                                    handleAszfChange
                                                                }
                                                                handleOrderComplete={() =>
                                                                    handleOrderComplete(
                                                                        orderData
                                                                            .orderData[0]
                                                                            .id
                                                                    )
                                                                }
                                                                handleSelectedPaymentMethodChange={
                                                                    handleSelectedPaymentMethodChange
                                                                }
                                                                needsShipping={
                                                                    false
                                                                }
                                                            />
                                                            <br />
                                                        </>
                                                    )}

                                                <IonCard>
                                                    <IonCardContent>
                                                        <div>
                                                            <br />
                                                            <div className="ion-text-center">
                                                                {getPaymentStatusAlert(
                                                                    orderData
                                                                        .orderData[0]
                                                                        .payment_status
                                                                )}
                                                            </div>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <div className="orderData">
                                                                <OrderInfo
                                                                    order={
                                                                        orderData
                                                                            .orderData[0]
                                                                    }
                                                                    border={
                                                                        false
                                                                    }
                                                                    details={
                                                                        true
                                                                    }
                                                                    buttons={
                                                                        true
                                                                    }
                                                                    onChangeOrCancel={
                                                                        handleOrderChangeOrCancel
                                                                    }
                                                                />
                                                            </div>
                                                            {orderData
                                                                .orderData[0]
                                                                ?.invoices
                                                                .length > 0 && (
                                                                <div>
                                                                    {orderData.orderData[0].invoices.map(
                                                                        (
                                                                            invoice: Modelinvoice,
                                                                            index: number
                                                                        ) => (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <a
                                                                                    href={
                                                                                        invoice.public_url ??
                                                                                        "#"
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener"
                                                                                >
                                                                                    {invoice.is_dealer ===
                                                                                    1 ? (
                                                                                        <>
                                                                                            Szállítói
                                                                                            számla
                                                                                            letöltése
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            Számla
                                                                                            letöltése
                                                                                        </>
                                                                                    )}
                                                                                </a>
                                                                                <br />
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                ) : (
                                    <IonGrid className="marginInner">
                                        <IonRow className="ion-text-center">
                                            <IonCol size="12">
                                                <IonCard className="centerCard">
                                                    <IonCardHeader>
                                                        <IonCardTitle>
                                                            Rendelés nem
                                                            található.
                                                        </IonCardTitle>
                                                    </IonCardHeader>
                                                    <IonCardContent>
                                                        <IonButton
                                                            expand="block"
                                                            routerDirection="root"
                                                            routerLink="/"
                                                        >
                                                            Vissza az étlapra
                                                        </IonButton>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <IonCard className="centerCard">
                        <IonCardHeader>
                            <IonCardTitle>Jelentkezzen be</IonCardTitle>
                            <IonCardSubtitle>
                                A rendelés megtekintéséhez kérem regisztráljon
                                vagy jelentkezzen be!
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <IonButton
                                expand="block"
                                onClick={onOpenLoginModal}
                            >
                                Bejelentkezés
                            </IonButton>
                            <IonButton
                                expand="block"
                                color="medium"
                                onClick={onOpenRegModal}
                            >
                                Regiszráció
                            </IonButton>
                        </IonCardContent>
                    </IonCard>
                )}
            </PageWrapper>
        </div>
    );
};

export default CheckoutCompletePage;
