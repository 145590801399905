import moment from "moment";
import { HttpClient } from "../helpers/HttpClient";
import { ModelCategory, ModelCategoryGroup } from "../models/CategoryModel";
import { DEFAULT_CALORIE, DEFAULT_CARB, ModelBanner, ModelCalorie, ModelFilter, ModelFood, ModelImage, ModelMenuIcon, ModelMenuItem } from "../models/FoodModel";
import Axios from "axios";

export const getActualWeeks = async () => {
  return await HttpClient({
    url: "api/v1/weeks",
    method: "get",
  });
};

export const getCurrentWeek = async () => {
  return await HttpClient({
    url: "api/v1/current-week",
    method: "get",
  });
};

let cancelGetBannersToken: any;
export const getBanners = async (year: number, week: number) => {
  if (typeof cancelGetBannersToken != typeof undefined) {
    cancelGetBannersToken.cancel("REQ_CANCEL");
  }

  cancelGetBannersToken = Axios.CancelToken.source();

  return await HttpClient({
    url: "api/v1/banners",
    method: "get",
    params: {
      year: year,
      week: week,
    },
    cancelToken: cancelGetBannersToken.token,
  });
};

let cancelGetTopBannersToken: any;
export const getTopBanners = async (year: number, week: number) => {
  if (typeof cancelGetTopBannersToken != typeof undefined) {
    cancelGetTopBannersToken.cancel("REQ_CANCEL");
  }

  cancelGetTopBannersToken = Axios.CancelToken.source();

  return await HttpClient({
    url: "api/v1/top-banners",
    method: "get",
    params: {
      year: year,
      week: week,
    },
    cancelToken: cancelGetTopBannersToken.token,
  });
};

export const processBanners = (res: any) => {
  let tempBanners = new Array<ModelBanner>();
  Object.values(res.data.data).map((banner: any) => {
    tempBanners.push({
      id: banner.id,
      name: banner.name,
      category_id: banner.menu_category?.id ?? null,
      image: banner.image
        ? ({
            id: banner.image.id,
            uuid: banner.image.uuid,
          } as ModelImage)
        : null,
      mobile_image: banner.mobile_image
        ? ({
            id: banner.mobile_image.id,
            uuid: banner.mobile_image.uuid,
          } as ModelImage)
        : null,
      open_url: banner.open_url,
      show_on_mobile: Boolean(banner.show_on_mobile === 1),
      bg_color: banner.bg_color,
      font_color: banner.font_color,
      frontend_text: banner.frontend_text,
      frontend_title: banner.frontend_title,
      full_width: Boolean(banner.full_width === 1),
    });
  });
  return tempBanners;
};

let cancelGetMenuToken: any;
export const getMenu = async (
  year: number,
  week: number,
  filters: {
    filtersLoaded: boolean;
    filters: {
      labels: Array<ModelFilter>;
      ingredients: Array<ModelFilter>;
      favouritesOnly: boolean;
      lastMinutesOnly: boolean;
      calorie: ModelCalorie;
      carb: ModelCalorie;
    };
  },
  version?: number
) => {
  if (typeof cancelGetMenuToken != typeof undefined) {
    cancelGetMenuToken.cancel("REQ_CANCEL");
  }

  cancelGetMenuToken = Axios.CancelToken.source();

  let postData: any = {
    year: year,
    week: week,
    seek_labels: filters.filters.labels
      .filter((label: ModelFilter) => label.enabled === "enabled")
      .map((label: ModelFilter) => {
        return label.id;
      }),
    ignore_labels: filters.filters.labels
      .filter((label: ModelFilter) => label.enabled === "disabled")
      .map((label: ModelFilter) => {
        return label.id;
      }),
    seek_ingredients: filters.filters.ingredients
      .filter((ingerdient: ModelFilter) => ingerdient.enabled === "enabled")
      .map((ingerdient: ModelFilter) => {
        return ingerdient.id;
      }),
    ignore_ingredients: filters.filters.ingredients
      .filter((ingerdient: ModelFilter) => ingerdient.enabled === "disabled")
      .map((ingerdient: ModelFilter) => {
        return ingerdient.id;
      }),
    calorie: {
      min: filters.filters.calorie.lower ?? DEFAULT_CALORIE.lower,
      max: filters.filters.calorie.upper ? (filters.filters.calorie.upper === 2000 ? 9000 : filters.filters.calorie.upper) : DEFAULT_CALORIE.upper,
    },
    carb: {
      min: filters.filters.carb.lower ?? DEFAULT_CARB.lower,
      max: filters.filters.carb.upper ? (filters.filters.carb.upper === 200 ? 9000 : filters.filters.carb.upper) : DEFAULT_CARB.upper,
    },
    protein: {
      min: 0,
      max: 9000,
    },
    fat: {
      min: 0,
      max: 9000,
    },
    price: {
      min: 0,
      max: 9000,
    },
    favorites: filters.filters.favouritesOnly,
    last_minute: filters.filters.lastMinutesOnly,
  };

  if (version) {
    postData.version = version;
  }

  return await HttpClient({
    url: "api/v1/menu",
    method: "post",
    data: postData,
    cancelToken: cancelGetMenuToken.token,
  });
};

export const getDays = async (year: number, week: number) => {
  return await HttpClient({
    url: "api/v1/days",
    method: "get",
    params: {
      year: year,
      week: week,
    },
  });
};

export const postRating = async (food_id: number, rating: number, comment: string) => {
  return await HttpClient({
    url: "api/v1/rate",
    method: "post",
    data: {
      food_id,
      rating,
      comment,
    },
  });
};

export const getRating = async (food_id: number) => {
  return await HttpClient({
    url: "api/v1/rate",
    method: "get",
    params: {
      food_id,
    },
  });
};

export const getOrderRating = async (order_id: string, date: string) => {
  return await HttpClient({
    url: "api/v1/rate-order",
    method: "get",
    params: {
      order_id,
      date,
    },
  });
};

export const processCategoryGroups = (res: any, dates: any): ModelCategoryGroup[] => {
  let tempCategoryGroups: Array<ModelCategoryGroup> = [];
  Object.values(res.data.data).map((categoryGroup: any, _: number) => {
    let tempCategories: Array<ModelCategory> = [];
    categoryGroup.categories.map((category: any, _: number) => {
      let tempMenuItems: Array<ModelMenuItem> = [];
      dates.map((date: { date: Date; shipping_mode: string }, dateIndex: number) => {
        getCategoryFood(tempMenuItems, category, dateIndex, date);
      });

      tempCategories.push({
        id: category.id,
        code: category.code,
        name: category.name,
        background_color: category.background_color,
        font_color: category.font_color,
        nutrients_visible: category.nutrients_visible === 1,
        food_bg_color: category.food_bg_color,
        food_text_color: category.food_text_color,
        description: category.description,
        sequence: category.sequence,
        menu_items: tempMenuItems,
        big_portion_category_id: category.big_portion_category_id,
        weekly_menus:
          category.weekly_menus && category.weekly_menus.length > 0
            ? [
                {
                  id: category.weekly_menus[0].id,
                  code: category.weekly_menus[0].code,
                  name: category.weekly_menus[0].name,
                  background_color: category.weekly_menus[0].background_color,
                  font_color: category.weekly_menus[0].font_color,
                  nutrients_visible: category.weekly_menus[0].nutrients_visible === 1,
                  food_bg_color: category.weekly_menus[0].food_bg_color,
                  food_text_color: category.weekly_menus[0].food_text_color,
                  description: category.weekly_menus[0].description,
                  sequence: category.weekly_menus[0].sequence,
                  menu_items: getWeeklyMenuItems(dates, category.weekly_menus[0]),
                  big_portion_category_id: category.weekly_menus[0].big_portion_category_id,
                  weekly_menu_of_category_id: category.weekly_menus[0].weekly_menu_of_category_id,
                },
              ]
            : [],
        weekly_menu_of_category_id: null,
      } as ModelCategory);
    });
    tempCategoryGroups.push({
      id: categoryGroup.id,
      name: categoryGroup.name,
      sequence: categoryGroup.sequence,
      show_title: Boolean(categoryGroup.show_title === 1),
      title: categoryGroup.title,
      background_color: categoryGroup.background_color !== "" ? categoryGroup.background_color : "#000000",
      font_color: categoryGroup.font_color !== "" ? categoryGroup.font_color : "#ffffff",
      show_navigation: categoryGroup.show_navigation,
      categories: tempCategories,
      watermark_id: categoryGroup.watermark_id ?? null,
    });
  });
  return tempCategoryGroups;
};
export const getFilters = async () => {
  return await HttpClient({
    url: "api/v1/filters",
    method: "get",
  });
};

export const processFilters = (res: any, type?: string) => {
  let tempFilters = {
    labels: new Array<ModelFilter>(),
    ingredients: new Array<ModelFilter>(),
    favouritesOnly: false,
    lastMinutesOnly: false,
    calorie: DEFAULT_CALORIE,
    carb: DEFAULT_CARB,
  };

  Object.values(res.data.data.labels).map((label: any) => {
    var enabled: "enabled" | "disabled" | "default" = "default";

    if (type === "vitalkonyha" && label.id == 1) {
      enabled = "enabled";
    }
    if (type === "paleo" && label.id == 2) {
      enabled = "enabled";
    }

    tempFilters.labels.push({
      id: label.id,
      name: label.name,
      enabled: enabled,
      sequence: 0,
    });
  });

  Object.values(res.data.data.ingredients).map((ingredient: any) => {
    tempFilters.ingredients.push({
      id: ingredient.id,
      name: ingredient.name,
      enabled: "default",
      sequence: ingredient.sequence,
    });
  });

  return tempFilters;
};

const getWeeklyMenuItems = (dates: any, category: any) => {
  let tempWeeklyMenuItems: Array<ModelMenuItem> = [];
  dates.map((date: { date: Date; shipping_mode: string }, dateIndex: number) => {
    getCategoryFood(tempWeeklyMenuItems, category, dateIndex, date);
  });
  return tempWeeklyMenuItems;
};

const getCategoryFood = (tempMenuItems: Array<ModelMenuItem>, category: any, dateIndex: number, date: { date: Date; shipping_mode: string } = null) => {
  let tempMenuItemForDate = category.items.find((item: any) => {
    return moment(item.date).isSame(date.date, "day");
  });

  if (tempMenuItemForDate !== undefined) {
    let tempFood = {
      id: tempMenuItemForDate.food.id,
      name: tempMenuItemForDate.food.name,
      display_name: tempMenuItemForDate.food.display_name,
      image_id: tempMenuItemForDate.food.image_id,
      food_rating_cache: tempMenuItemForDate.food.food_rating_cache,
      rating_count: tempMenuItemForDate.food.rating_count,
      is_favourite: Boolean(tempMenuItemForDate.food.users?.length > 0),
    } as ModelFood;
    // Ha van az ételhez ikon
    let tempIcons: Array<ModelMenuIcon> = [];
    if ((tempMenuItemForDate?.menu_icons?.length ?? 0) > 0) {
      tempMenuItemForDate.menu_icons.forEach((icon: any) => {
        tempIcons.push({
          id: icon.id,
          name: icon.name,
          open_url: icon.open_url,
          tooltip: icon.tooltip,
          position: icon.pivot.position,
          image: icon.image,
        });
      });
    }

    tempMenuItems[dateIndex] = {
      id: tempMenuItemForDate.id,
      food: tempFood,
      price: tempMenuItemForDate.price,
      price_without_discount: tempMenuItemForDate.price_without_discount ?? null,
      date: tempMenuItemForDate.date,
      order_disabled_at: tempMenuItemForDate.order_disabled_at,
      order_disabled_quota_at: tempMenuItemForDate.order_disabled_quota_at ?? null,
      disabled: tempMenuItemForDate.disabled,
      is_last_minute_orderable: tempMenuItemForDate.is_last_minute_orderable,
      description: tempMenuItemForDate.description,
      vat: tempMenuItemForDate.vat,
      icons: tempIcons,

      energy_portion_food_one: tempMenuItemForDate.energy_portion_food_one,
      fat_portion_food_one: tempMenuItemForDate.fat_portion_food_one,
      saturated_fat_portion_food_one: tempMenuItemForDate.saturated_fat_portion_food_one,
      carb_portion_food_one: tempMenuItemForDate.carb_portion_food_one,
      sugar_portion_food_one: tempMenuItemForDate.sugar_portion_food_one,
      protein_portion_food_one: tempMenuItemForDate.protein_portion_food_one,
      salt_portion_food_one: tempMenuItemForDate.salt_portion_food_one,
      energy_hundred_food_one: tempMenuItemForDate.energy_hundred_food_one,
      fat_hundred_food_one: tempMenuItemForDate.fat_hundred_food_one,
      saturated_fat_hundred_food_one: tempMenuItemForDate.saturated_fat_hundred_food_one,
      carb_hundred_food_one: tempMenuItemForDate.carb_hundred_food_one,
      sugar_hundred_food_one: tempMenuItemForDate.sugar_hundred_food_one,
      protein_hundred_food_one: tempMenuItemForDate.protein_hundred_food_one,
      salt_hundred_food_one: tempMenuItemForDate.salt_hundred_food_one,
      vat_food_one: tempMenuItemForDate.vat_food_one,
      weight_food_one: tempMenuItemForDate.weight_food_one,
      food_name_one: tempMenuItemForDate.food_name_one,

      energy_portion_food_two: tempMenuItemForDate.energy_portion_food_two,
      fat_portion_food_two: tempMenuItemForDate.fat_portion_food_two,
      saturated_fat_portion_food_two: tempMenuItemForDate.saturated_fat_portion_food_two,
      carb_portion_food_two: tempMenuItemForDate.carb_portion_food_two,
      sugar_portion_food_two: tempMenuItemForDate.sugar_portion_food_two,
      protein_portion_food_two: tempMenuItemForDate.protein_portion_food_two,
      salt_portion_food_two: tempMenuItemForDate.salt_portion_food_two,
      energy_hundred_food_two: tempMenuItemForDate.energy_hundred_food_two,
      fat_hundred_food_two: tempMenuItemForDate.fat_hundred_food_two,
      saturated_fat_hundred_food_two: tempMenuItemForDate.saturated_fat_hundred_food_two,
      carb_hundred_food_two: tempMenuItemForDate.carb_hundred_food_two,
      sugar_hundred_food_two: tempMenuItemForDate.sugar_hundred_food_two,
      protein_hundred_food_two: tempMenuItemForDate.protein_hundred_food_two,
      salt_hundred_food_two: tempMenuItemForDate.salt_hundred_food_two,
      vat_food_two: tempMenuItemForDate.vat_food_two,
      weight_food_two: tempMenuItemForDate.weight_food_two,
      food_name_two: tempMenuItemForDate.food_name_two,

      energy_portion_food_three: tempMenuItemForDate.energy_portion_food_three,
      fat_portion_food_three: tempMenuItemForDate.fat_portion_food_three,
      saturated_fat_portion_food_three: tempMenuItemForDate.saturated_fat_portion_food_three,
      carb_portion_food_three: tempMenuItemForDate.carb_portion_food_three,
      sugar_portion_food_three: tempMenuItemForDate.sugar_portion_food_three,
      protein_portion_food_three: tempMenuItemForDate.protein_portion_food_three,
      salt_portion_food_three: tempMenuItemForDate.salt_portion_food_three,
      energy_hundred_food_three: tempMenuItemForDate.energy_hundred_food_three,
      fat_hundred_food_three: tempMenuItemForDate.fat_hundred_food_three,
      saturated_fat_hundred_food_three: tempMenuItemForDate.saturated_fat_hundred_food_three,
      carb_hundred_food_three: tempMenuItemForDate.carb_hundred_food_three,
      sugar_hundred_food_three: tempMenuItemForDate.sugar_hundred_food_three,
      protein_hundred_food_three: tempMenuItemForDate.protein_hundred_food_three,
      salt_hundred_food_three: tempMenuItemForDate.salt_hundred_food_three,
      vat_food_three: tempMenuItemForDate.vat_food_three,
      weight_food_three: tempMenuItemForDate.weight_food_three,
      food_name_three: tempMenuItemForDate.food_name_three,
      category: category,
      weekly_name: tempMenuItemForDate.weekly_name,
      type: tempMenuItemForDate.type,
      comment: tempMenuItemForDate.comment ?? null,
    } as ModelMenuItem;
  } else if (date) {
    tempMenuItems[dateIndex] = undefined;
  }
};
