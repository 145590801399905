import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
} from "@ionic/react";
import React from "react";
import PageWrapper from "../layout/PageWrapper";
import { addAnalytics } from "../services/AnalyticsService";
import { sendBarionPixel } from "./barion/PixelHelper";

interface ErrorPorps {
    children: any;
}

interface ErrorState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorPorps, ErrorState> {
    constructor(props: ErrorPorps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        addAnalytics("error", errorInfo);
        sendBarionPixel("error", {
            description: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <PageWrapper smallContainer={true}>
                        <IonCard className="centerCard">
                            <IonCardHeader>
                                <IonCardTitle>Hiba történt</IonCardTitle>
                                <IonCardSubtitle>
                                    A hiba elhárításán kollégáink már dolgoznak!
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <p className="ion-text-left">
                                    <b>Tel.:</b> 06 (1) 700 42 12 • 06 (1) 700
                                    42 16 (munkanapokon 8-15 óráig)
                                </p>
                                <p className="ion-text-left">
                                    <b>Email:</b>{" "}
                                    <a href="mailto:info@cityfood.hu">
                                        info@cityfood.hu
                                    </a>
                                    <br />
                                </p>
                                <p className="ion-text-left">
                                    <b>Hibabejelentés:</b>{" "}
                                    <a href="mailto:eszrevetel@cityfood.hu">
                                        eszrevetel@cityfood.hu
                                    </a>
                                    <br />
                                </p>
                                <p className="ion-text-left">
                                    <b>Cím:</b> 2220 Vecsés, Új Ecseri út 2.
                                </p>
                            </IonCardContent>
                        </IonCard>
                    </PageWrapper>
                </>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
