import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { useDispatch } from "react-redux";
import OrderInfo from "../../../../components/OrderInfo";
import { ModelOrderData } from "../../../../models/ShippingModel";
import { getCancellableOrders, processOrderData } from "../../../../services/ShippingService";
import { setProfileModal } from "../Actions";
import { handleApiError, handleApiResponseError } from "../../../../helpers/form/GlobalHelpers";
import { calendar } from "ionicons/icons";

const OrdersCancelTab: React.FC = () => {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState<{
    orderDataLoaded: boolean;
    orderData: Array<ModelOrderData>;
    total: number;
  }>({
    orderDataLoaded: false,
    orderData: [],
    total: 0,
  });

  const fetchOrderData = () => {
    getCancellableOrders()
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a rendelések lekérdezésekor, kérjük próbálja újra később...")) {
          setOrderData({
            orderDataLoaded: true,
            orderData: [],
            total: 0,
          });
          return;
        }
        setOrderData({
          orderDataLoaded: true,
          orderData: processOrderData(res?.data?.data ?? []),
          total: res?.data?.data?.total ?? 0,
        });
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a rendelések lekérdezésekor, kérjük próbálja újra később...");
        setOrderData({
          orderDataLoaded: true,
          orderData: [],
          total: 0,
        });
      });
  };

  const handleOrderChangeOrCancel = () => {
    fetchOrderData();
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <>
      <h1>
        Lemondás
        <IonButton
              size="small"
              routerDirection="forward"
              routerLink="/rendeles-osszesito"
              onClick={() => dispatch(setProfileModal(false, "data"))}
          >
              <IonIcon slot="start" icon={calendar} />
              Összesített nézet
          </IonButton>
      </h1>
      <p className="modal-subtitle">Ebben a menüpontban azokat a rendeléseket láthatja, amelyekből még lehet lemondani.</p>
      {orderData.orderDataLoaded ? (
        <>
          {orderData.orderData.length > 0 ? (
            <div className="orderData-wrapper">
              {orderData.orderData.map((order: ModelOrderData, key: number) => (
                <div style={{ marginBottom: "15px" }} key={order.id}>
                  <OrderInfo
                    order={order}
                    border={true}
                    details={false}
                    buttons={false}
                    onClick={() => dispatch(setProfileModal(false, "orders"))}
                    routerLink={"/rendeles-informacio/" + order.id}
                    routerDirection="forward"
                    key={key}
                    onChangeOrCancel={handleOrderChangeOrCancel}
                  />
                </div>
              ))}
            </div>
          ) : (
            <h2>Nem található rendelés...</h2>
          )}
        </>
      ) : (
        <div className="ion-padding ion-text-center">
          <IonSpinner />
        </div>
      )}
    </>
  );
};

export default OrdersCancelTab;
