import React, { useEffect, useState } from "react";
import { Route, Redirect, Switch, useLocation, useHistory } from "react-router";
import moment from "moment";
import Axios from "axios";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";

import { logout, getUser } from "./services/UserService";
import LocalStorage from "./helpers/LocalStorage";
import { getActualWeeks, getCurrentWeek } from "./services/FoodService";
import { AuthContext } from "./context/AuthContext";
import { WeeksContext } from "./context/WeeksContext";
import { useDispatch } from "react-redux";

import { addToast } from "./store/toast/Actions";
import { ADD_TOAST } from "./store/toast/ActionTypes";
import { loadCart } from "./store/cart/Reducer";
import { emptyCart } from "./store/cart/Actions";

import OrderPage from "./pages/OrderPage";
import PasswordRecoveryPage from "./pages/PasswordRecoveryPage";
import OrderRatePage from "./pages/OrderRatePage";
import CheckoutPage from "./pages/CheckoutPage";
import CheckoutCompletePage from "./pages/CheckoutCompletePage";
import YbCheckoutCompletePage from "./pages/YbCheckoutCompletePage";
import NotFoundPage from "./pages/NotFoundPage";
import LastMinutePage from "./pages/LastMinutePage";
import SummedOrdersPage from "./pages/SummedOrdersPage";

import LoginModal from "./store/modals/login/LoginModal";
import LostPasswordModal from "./store/modals/lostpassword/LostPasswordModal";
import RegistrationModal from "./store/modals/registration/RegistrationModal";
import ProfileModal from "./store/modals/profile/ProfileModal";
import RatingModal from "./store/modals/rating/RatingModal";
import AddressModal from "./store/modals/address/AddressModal";
import MessageModal from "./store/modals/message/MessageModal";
import CategoryReorderModal from "./store/modals/categoryreorder/CategoryReorderModal";
import PersonModal from "./store/modals/person/PersonModal";

import RegistrationConfirmModal from "./components/RegistrationConfirmModal";
import GdprConfirmModal from "./components/GdprConfirmModal";
import CartDetailsMenu from "./components/CartDetailsMenu";
import BarionPixelConsent from "./components/BarionPixelConsent";

import { handleApiError, handleApiResponseError } from "./helpers/form/GlobalHelpers";

import "./App.scss";
import "./theme/Responsive.scss";
import { addAnalytics } from "./services/AnalyticsService";
import PageDeliveryAreas from "./pages/PageDeliveryAreas";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const [barionPixelConsent, setBarionPixelConsent] = useState<boolean | null>(LocalStorage.get("barion_pixel_consent") ? (LocalStorage.get("barion_pixel_consent") === "true" ? true : false) : null);
  const changeBarionPixelConsent = (consent: boolean) => {
    setBarionPixelConsent(consent);
  };
  const [weeks, setWeeks] = useState<
    Array<{
      year: number;
      week: number;
      disabled?: boolean;
    }>
  >([]);
  const [currentWeek, setCurrentWeek] = useState<{
    weekData: { year: number | null; week: number | null };
    weekDataLoaded: boolean;
  }>({ weekData: { year: null, week: null }, weekDataLoaded: false });

  const fetchWeeks = () => {
    getActualWeeks()
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a hetek lekérdezése közben...")) {
          return;
        }
        if (res.data.data.length > 0) {
          setWeeks(res.data.data);
        } else {
          dispatch(
            addToast({
              color: "danger",
              message: "Még nincs feltöltve hét",
              duration: 8000,
            })
          );
        }
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a hetek lekérdezése közben...");
      });
  };

  const fetchCurrentWeek = () => {
    getCurrentWeek()
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt az aktuális hét lekérdezése közben...")) {
          return;
        }
        setCurrentWeek({
          weekData: res.data.data,
          weekDataLoaded: true,
        });
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt az aktuális hét lekérdezése közben...");
      });
  };

  const [authInfo, setAuthInfo] = useState<any>({
    loggedIn: Boolean(LocalStorage.get("authToken")),
    token: LocalStorage.get("authToken"),
    anonymousToken: LocalStorage.get("anonymousToken"),
    userInfo: {
      email_verified_at: LocalStorage.get("emailVerifiedAt"),
      statement_accepted_at: LocalStorage.get("statementAcceptedAt"),
      name: LocalStorage.get("userName"),
    },
  });

  const setAuth = async (login: boolean, token?: string) => {
    if (LocalStorage.get("authToken") !== token) {
      LocalStorage.set("authToken", token);
    }

    if (login) {
      getUser()
        .then((res: any) => {
          if (handleApiResponseError(dispatch, res, "Hiba történt a felhasználó lekérdezése közben...")) {
            return;
          }

          setAuthInfo((prevState: any) => ({
            ...prevState,
            loggedIn: login,
            token: token,
            userInfo: res.data.data,
          }));
        })
        .catch((err: any) => {
          handleApiError(dispatch, err, "Hiba történt a felhasználó lekérdezése közben...");
        })
        .finally(() => {
          dispatch(loadCart());
        });
    } else {
      setAuthInfo((prevState: any) => ({
        ...prevState,
        loggedIn: login,
        token: token,
        userInfo: null,
      }));
    }
  };

  const setLogout = (isForced?: boolean) => {
    if (LocalStorage.get("authToken") !== null) {
      if (isForced) {
        dispatch(emptyCart());
        setAuth(false, null);
      } else {
        logout().finally(() => {
          dispatch(emptyCart());
          setAuth(false, null);
          dispatch({
            type: ADD_TOAST,
            payload: {
              toast: {
                message: "Sikeres kijelentkezés.",
                duration: 3000,
                color: "success",
              },
            },
          });
        });
      }
    }
  };

  const history = useHistory();
  useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);
  const trackPageView = () => {
    addAnalytics("page_view", window.location.pathname);
  };

  useEffect(() => {
    fetchCurrentWeek();
    fetchWeeks();
    setAuth(Boolean(LocalStorage.get("authToken")), LocalStorage.get("authToken"));
    dispatch(loadCart());
  }, []);

  useEffect(() => {
    LocalStorage.set("emailVerifiedAt", authInfo.userInfo ? authInfo.userInfo.email_verified_at : null);
    LocalStorage.set("statementAcceptedAt", authInfo.userInfo ? authInfo.userInfo.statement_accepted_at : null);
    LocalStorage.set("userName", authInfo.userInfo ? authInfo.userInfo.name : null);
  }, [authInfo]);

  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        setLogout();
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        authInfo: authInfo,
        setAuthInfo: setAuthInfo,
        setLogout: setLogout,
        setAuth: setAuth,
      }}
    >
      <ClearBrowserCacheBoundary auto={true} duration={60000}>
        <WeeksContext.Provider
          value={{
            weeks: weeks,
          }}
        >
          <Switch>
            <Route exact path="/rendeles-leadas">
              <CheckoutPage />
            </Route>
            <Route exact path="/rendeles-informacio/:orderId?">
              <CheckoutCompletePage />
            </Route>
            <Route exact path="/rendeles-osszesito">
              <SummedOrdersPage />
            </Route>
            <Route exact path="/gateway-back">
              <YbCheckoutCompletePage />
            </Route>
            <Route exact path="/last-minute">
              <LastMinutePage currentWeek={currentWeek} />
            </Route>
            <Route exact path="/delivery-areas">
              <PageDeliveryAreas />
            </Route>
            <Route exact path="/rendeles/:year/:week">
              <OrderPage />
            </Route>
            {/*
                        <Route exact path="/paleo-rendeles/:year/:week">
                            <OrderPage type="paleo" />
                        </Route>
                        <Route exact path="/vitalkonyha-rendeles/:year/:week">
                            <OrderPage type="vitalkonyha" />
                        </Route>
                        */}
            <Route exact path="/rendeles/:year/:week/:version">
              {authInfo.loggedIn ? <OrderPage /> : currentWeek.weekDataLoaded ? <Redirect to={`/rendeles/${moment(moment()).year()}/${moment(moment()).week()}`} /> : null}
            </Route>
            <Route exact path="/rendeles">
              {currentWeek.weekDataLoaded && <Redirect to={`/rendeles/${moment(moment()).year()}/${moment(moment()).week()}`} />}
            </Route>
            {currentWeek.weekDataLoaded && <Redirect exact path="/" to={`/rendeles/${currentWeek.weekData.year}/${currentWeek.weekData.week}`} />}
            <Route exact path="/jelszo-visszaallitas/:hash/:email">
              <PasswordRecoveryPage onLogin={(login: boolean, token: string) => setAuth(login, token)} />
            </Route>
            <Route exact path="/rendeles-ertekeles/:order_id/:day_date">
              <OrderRatePage />
            </Route>
            {currentWeek.weekDataLoaded && (
              <Route>
                <NotFoundPage />
              </Route>
            )}
          </Switch>
        </WeeksContext.Provider>

        <CartDetailsMenu />

        <LoginModal onLogin={(login: boolean, token: string) => setAuth(login, token)} />
        <LostPasswordModal />
        <RegistrationModal onRegistration={(login: boolean, token: string) => setAuth(login, token)} />
        <RegistrationConfirmModal onLogout={() => setLogout()} setUserData={setAuthInfo} />
        <GdprConfirmModal onLogout={() => setLogout()} setUserData={setAuthInfo} />
        <ProfileModal />
        <RatingModal />
        <AddressModal />
        <PersonModal />
        <MessageModal />
        <CategoryReorderModal />
        <BarionPixelConsent show={barionPixelConsent === null} onChange={changeBarionPixelConsent} />
      </ClearBrowserCacheBoundary>
    </AuthContext.Provider>
  );
};

export default App;
