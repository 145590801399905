import React, { useEffect, useState } from "react";
import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { useDispatch } from "react-redux";
import OrderInfo from "../../../../components/OrderInfo";
import { ModelOrderData } from "../../../../models/ShippingModel";
import { getOrders, processOrderData } from "../../../../services/ShippingService";
import { setProfileModal } from "../Actions";
import { handleApiError, handleApiResponseError } from "../../../../helpers/form/GlobalHelpers";
import InfiniteScroll from "react-infinite-scroll-component";
import { ModelPerson } from "../../../../models/Persons";
import { getPersons } from "../../../../services/ProfileService";
import Select from "react-select";
import SelectStyle from "../../../../helpers/SelectStyle";
import moment from "moment";
import { calendar } from "ionicons/icons";

const OrdersTab: React.FC = () => {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState<{
    orderDataLoaded: boolean;
    orderData: Array<ModelOrderData>;
    total: number;
  }>({
    orderDataLoaded: false,
    orderData: [],
    total: 0,
  });
  const [persons, setPersons] = useState<{
    persons: ModelPerson[];
    loaded: boolean;
  }>({
    persons: [],
    loaded: false,
  });
  const [selectedPerson, setSelectedPerson] = useState<string | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>(moment().format("yyyy"));
  const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("M"));

  const fetchOrderData = () => {
    getOrders(parseInt(selectedYear), parseInt(selectedMonth), selectedPerson)
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a rendelések lekérdezésekor, kérjük próbálja újra később...")) {
          setOrderData({
            orderDataLoaded: true,
            orderData: [],
            total: 0,
          });
          return;
        }
        setOrderData({
          orderDataLoaded: true,
          orderData: processOrderData(res?.data?.data ?? []),
          total: res?.data?.data?.total ?? 0,
        });
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a rendelések lekérdezésekor, kérjük próbálja újra később...");
        setOrderData({
          orderDataLoaded: true,
          orderData: [],
          total: 0,
        });
      });
  };

  const handleOrderChangeOrCancel = () => {
    fetchOrderData();
  };

  const refreshPersons = () => {
    setPersons({ persons: [], loaded: false });
    getPersons()
      .then((res: any) => {
        if (handleApiResponseError(dispatch, res, "Hiba történt a személyek lekérdezése közben...")) {
          return;
        }
        setPersons({ persons: res.data.data, loaded: true });
      })
      .catch((err: any) => {
        handleApiError(dispatch, err, "Hiba történt a személyek lekérdezése közben...");
        setPersons({ persons: [], loaded: true });
      });
  };

  const getYears = () => {
    const date = new Date();
    const year = date.getFullYear();
    const years: any = [];
    for (let i = 2022; i <= year; i++) {
      years.push({ value: i, label: i });
    }
    return years;
  };

  const personsArray: any = persons.persons.map((item) => ({ value: item.id, label: item.name }));
  const monthsArray: any = [
    { value: "1", label: "Január" },
    { value: "2", label: "Február" },
    { value: "3", label: "Március" },
    { value: "4", label: "Április" },
    { value: "5", label: "Május" },
    { value: "6", label: "Június" },
    { value: "7", label: "Július" },
    { value: "8", label: "Augusztus" },
    { value: "9", label: "Szeptember" },
    { value: "10", label: "Október" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const yearsArray: any = getYears();

  useEffect(() => {
    refreshPersons();
  }, []);

  useEffect(() => {
    fetchOrderData();
  }, [selectedPerson, selectedYear, selectedMonth]);

  return (
    <>
      <h1>
        Rendeléseim
        <IonButton
              size="small"
              routerDirection="forward"
              routerLink="/rendeles-osszesito"
              onClick={() => dispatch(setProfileModal(false, "data"))}
          >
              <IonIcon slot="start" icon={calendar} />
              Összesített nézet
          </IonButton>
      </h1>
      <div className="orders-head-grid">
        <Select
          options={yearsArray}
          onChange={(value: any) => {
            setSelectedYear(value?.value);
          }}
          defaultValue={yearsArray.find((i: any) => i.value == moment().format("yyyy"))}
          noOptionsMessage={() => "Nincs találat"}
          placeholder="Év"
          styles={SelectStyle}
          isClearable={false}
        />
        <Select
          options={monthsArray}
          onChange={(value: any) => {
            setSelectedMonth(value?.value);
          }}
          defaultValue={monthsArray.find((i: any) => i.value === moment().format("M"))}
          noOptionsMessage={() => "Nincs találat"}
          placeholder="Hónap"
          styles={SelectStyle}
          isClearable={false}
        />
      </div>
      <Select
        options={personsArray}
        onChange={(value: any) => {
          setSelectedPerson(value?.value);
        }}
        noOptionsMessage={() => "Nincs találat"}
        placeholder="Személy"
        styles={SelectStyle}
        isClearable={true}
      />

      {orderData.orderDataLoaded ? (
        <>
          {orderData.orderData.length > 0 ? (
            <div className="orderData-wrapper">
              {orderData.orderData.map((order: ModelOrderData, key: number) => (
                <div style={{ marginBottom: "15px" }} key={order.id}>
                  <OrderInfo
                    order={order}
                    border={true}
                    details={false}
                    buttons={false}
                    onClick={() => dispatch(setProfileModal(false, "orders"))}
                    routerLink={"/rendeles-informacio/" + order.id}
                    routerDirection="forward"
                    key={key}
                    onChangeOrCancel={handleOrderChangeOrCancel}
                  />
                </div>
              ))}
            </div>
          ) : (
            <h2>Nem található rendelés...</h2>
          )}
        </>
      ) : (
        <div className="ion-padding ion-text-center">
          <IonSpinner />
        </div>
      )}
    </>
  );
};

export default OrdersTab;
