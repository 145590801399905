import React from "react";

import "./Empty.scss";

interface IProps {
  children?: any;
}

const Empty: React.FC<IProps> = ({ children }) => {
  return <div className="empty">{children ?? ""}</div>;
};

export default Empty;
