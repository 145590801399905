import React from "react";
import moment from "moment";

import barion from "../assets/images/payment/barion-card-strip-intl.svg";
import szep from "../assets/images/szep.png";
import classNames from "classnames";

type Props = {
  children: any;
  smallContainer?: boolean;
  className?: string;
};

const PageWrapper: React.FC<Props> = ({ children, smallContainer, className }) => {
  return (
    <div id="App">
      <div className={classNames("Container", { smallContainer: smallContainer }, className)}>
        {children ?? ""}

        <footer>
          <div className="footer-row">
            <div className="left">© {moment().format("YYYY")} Cityfood - Több mint ebéd. Élmény! - Minden jog fenntartva</div>
            <div className="right">
              <a target="_blank" href={`${process.env.REACT_APP_ROOT_URL}/adatkezeles`} rel="noopener">
                Adatkezelés
              </a>
              <a target="_blank" href={`${process.env.REACT_APP_ROOT_URL}/adatvedelmi-szabalyzat`} rel="noopener">
                Adatvédelmi szabályzat
              </a>
              <a target="_blank" href={`${process.env.REACT_APP_ROOT_URL}/aszf`} rel="noopener">
                ÁSZF
              </a>
              <a target="_blank" href={`https://app.cityfood.hu/visszaeles-bejelentesi-szabalyzat`} rel="noopener">
                Visszaélés-bejelentési szabályzat
              </a>
            </div>
          </div>
          <div className="footer-row">
            <div className="szep">
              <a href="https://www.cityfood.hu/tudnivalok/" target="_blank" rel="noopener">
                <img src={szep} alt="" className="footer-szep" />
              </a>
            </div>
            <div className="barion">
              <a href="https://www.barion.com/" target="_blank" rel="noopener">
                <img src={barion} alt="Barion" className="footer-barion" />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PageWrapper;
